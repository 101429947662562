export const Languages = [
  { name: "Acehnese", code: "ace", direction: "" },
  { name: "Afrikaans", code: "af", direction: "" },
  { name: "Akan", code: "ak", direction: "" },
  { name: "Albanian", code: "sq", direction: "" },
  { name: "Amharic", code: "am", direction: "" },
  { name: "Arabic", code: "ar", direction: "rtl" },
  { name: "Armenian", code: "hy", direction: "" },
  { name: "Assamese", code: "as", direction: "" },
  { name: "Asturian", code: "ast", direction: "" },
  { name: "Awadhi", code: "awa", direction: "" },
  { name: "Ayacucho Quechua", code: "quy", direction: "" },
  { name: "Aymara", code: "ay", direction: "" },
  { name: "Aymara, Central", code: "ayr", direction: "" },
  { name: "Azerbaijani", code: "az", direction: "" },
  { name: "Azerbaijani, Northern", code: "azj", direction: "" },
  { name: "Azerbaijani, Southern", code: "azb", direction: "" },
  { name: "Balinese", code: "ban", direction: "" },
  { name: "Bambara", code: "bm", direction: "" },
  { name: "Bangla", code: "bn", direction: "" },
  // { name: "Bengali", code: "bn", direction: "" },
  { name: "Banjar", code: "bjn", direction: "" },
  { name: "Bashkir", code: "ba", direction: "" },
  { name: "Basque", code: "eu", direction: "" },
  { name: "Belarusian", code: "be", direction: "" },
  { name: "Bemba", code: "bem", direction: "" },
  { name: "Bengali", code: "bn", direction: "" },
  { name: "Bhojpuri", code: "bho", direction: "" },
  { name: "Bodo", code: "brx", direction: "" },
  { name: "Bosnian", code: "bs", direction: "" },
  { name: "Buginese", code: "bug", direction: "" },
  { name: "Bulgarian", code: "bg", direction: "" },
  { name: "Cantonese (Traditional)", code: "yue", direction: "" },
  { name: "Catalan", code: "ca", direction: "" },
  { name: "Cebuano", code: "ceb", direction: "" },
  { name: "Chhattisgarhi", code: "hne", direction: "" },
  { name: "Chinese (Literary)", code: "lzh", direction: "" },
  { name: "Chinese (Simplified)", code: "zh", direction: "" },
  { name: "Chinese (Traditional)", code: "zh-TW", direction: "" },
  { name: "Chokwe", code: "cjk", direction: "" },
  { name: "Corsican", code: "co", direction: "" },
  { name: "Crimean Tatar", code: "crh", direction: "" },
  { name: "Croatian", code: "hr", direction: "ltr" },
  { name: "Czech", code: "cs", direction: "ltr" },
  { name: "Danish", code: "da", direction: "ltr" },
  { name: "Dari", code: "fa-AF", direction: "ltr" },
  { name: "Dhivehi / Divehi", code: "dv", direction: "ltr" },
  { name: "Dimli", code: "diq", direction: "ltr" },
  { name: "Dinka (Southwestern)", code: "dik", direction: "ltr" },
  { name: "Dogri", code: "doi", direction: "ltr" },
  { name: "Dutch", code: "nl", direction: "ltr" },
  { name: "Dyula", code: "dyu", direction: "ltr" },
  { name: "Dzongkha", code: "dz", direction: "ltr" },
  { name: "English", code: "en", direction: "ltr" },
  { name: "Esperanto", code: "eo", direction: "ltr" },
  { name: "Estonian", code: "et", direction: "ltr" },
  { name: "Ewe", code: "ee", direction: "ltr" },
  { name: "Faroese", code: "fo", direction: "ltr" },
  { name: "Farsi", code: "fa", direction: "ltr" },
  { name: "Fijian", code: "fj", direction: "ltr" },
  { name: "Filipino, Tagalog", code: "tl", direction: "ltr" },
  { name: "Finnish", code: "fi", direction: "ltr" },
  { name: "Fon", code: "fon", direction: "ltr" },
  { name: "French", code: "fr", direction: "ltr" },
  { name: "French (Canada)", code: "fr-CA", direction: "ltr" },
  { name: "Frisian", code: "fy", direction: "ltr" },
  { name: "Friulian", code: "fur", direction: "ltr" },
  { name: "Galician", code: "gl", direction: "ltr" },
  { name: "Ganda", code: "lg", direction: "ltr" },
  { name: "Georgian", code: "ka", direction: "ltr" },
  { name: "German", code: "de", direction: "ltr" },
  { name: "Greek", code: "el", direction: "ltr" },
  { name: "Gujarati", code: "gu", direction: "ltr" },
  { name: "Haitian Creole", code: "ht", direction: "ltr" },
  { name: "Halh Mongolian", code: "khk", direction: "ltr" },
  { name: "Hausa", code: "ha", direction: "ltr" },
  { name: "Hawaiian", code: "haw", direction: "ltr" },
  { name: "Hebrew", code: "he", direction: "rtl" },
  { name: "Hindi", code: "hi", direction: "ltr" },
  { name: "Hmong", code: "hmn", direction: "ltr" },
  { name: "Hmong Daw", code: "mww", direction: "ltr" },
  { name: "Hungarian", code: "hu", direction: "ltr" },
  { name: "Icelandic", code: "is", direction: "ltr" },
  { name: "Igbo", code: "ig", direction: "ltr" },
  { name: "Ilocano / Iloko", code: "ilo", direction: "ltr" },
  { name: "Indonesian", code: "id", direction: "ltr" },
  { name: "Inuinnaqtun", code: "iu", direction: "ltr" },
  { name: "Inuktitut", code: "ikt", direction: "ltr" },
  { name: "Inuktitut (Latin)", code: "iu-Latn", direction: "ltr" },
  { name: "Irish", code: "ga", direction: "ltr" },
  { name: "Italian", code: "it", direction: "ltr" },
  { name: "Japanese", code: "ja", direction: "ltr" },
  { name: "Javanese", code: "jv", direction: "ltr" },
  { name: "Jingpho", code: "kac", direction: "ltr" },
  { name: "Kabiyè", code: "kbp", direction: "ltr" },
  { name: "Kabuverdianu", code: "kea", direction: "ltr" },
  { name: "Kabyle", code: "kab", direction: "ltr" },
  { name: "Kamba", code: "kam", direction: "ltr" },
  { name: "Kannada", code: "kn", direction: "ltr" },
  { name: "Kanuri, Central", code: "knc", direction: "ltr" },
  { name: "Kashmiri (Arabic)", code: "kas", direction: "ltr" },
  { name: "Kashmiri (Devanagari)", code: "ks", direction: "ltr" },
  { name: "Kazakh", code: "kk", direction: "ltr" },
  { name: "Khmer", code: "km", direction: "ltr" },
  { name: "Kikuyu", code: "ki", direction: "ltr" },
  { name: "Kimbundu", code: "kmb", direction: "ltr" },
  { name: "Kinyarwanda", code: "rw", direction: "ltr" },
  { name: "Klingon", code: "tlh-Latn", direction: "ltr" },
  { name: "Klingon (plqaD)", code: "tlh-Piqd", direction: "ltr" },
  { name: "Kongo", code: "kg", direction: "ltr" },
  { name: "Konkani", code: "gom", direction: "ltr" },
  { name: "Korean", code: "ko", direction: "ltr" },
  { name: "Krio", code: "kri", direction: "ltr" },
  { name: "Kurdish", code: "ku", direction: "ltr" },
  { name: "Kurdish (Central)", code: "ckb", direction: "ltr" },
  { name: "Kurdish (Northern)", code: "kmr", direction: "ltr" },
  { name: "Kurdish (Sorani)", code: "ckb", direction: "ltr" },
  { name: "Kyrgyz", code: "ky", direction: "ltr" },
  { name: "Lao", code: "lo", direction: "ltr" },
  { name: "Latgalian", code: "ltg", direction: "ltr" },
  { name: "Latin", code: "la", direction: "ltr" },
  { name: "Latvian", code: "lv", direction: "ltr" },
  { name: "Ligurian", code: "lij", direction: "ltr" },
  { name: "Limburgish", code: "li", direction: "ltr" },
  { name: "Lingala", code: "ln", direction: "ltr" },
  { name: "Lithuanian", code: "lt", direction: "ltr" },
  { name: "Lombard", code: "lmo", direction: "ltr" },
  { name: "Lower Sorbian", code: "dsb", direction: "ltr" },
  { name: "Luba-Kasai", code: "lua", direction: "ltr" },
  // { name: "Luganda", code: "lug", direction: "ltr" },
  { name: "Luxembourgish", code: "lb", direction: "ltr" },
  { name: "Macedonian", code: "mk", direction: "ltr" },
  { name: "Magahi", code: "mag", direction: "ltr" },
  { name: "Maithili", code: "mai", direction: "ltr" },
  { name: "Malagasy", code: "mg", direction: "ltr" },
  { name: "Malay", code: "ms", direction: "ltr" },
  { name: "Malayalam", code: "ml", direction: "ltr" },
  { name: "Maltese", code: "mt", direction: "ltr" },
  { name: "Manipuri", code: "mni", direction: "ltr" },
  { name: "Maori", code: "mi", direction: "ltr" },
  { name: "Marathi", code: "mr", direction: "ltr" },
  { name: "Meiteilon (Manipuri)", code: "mni-Mtei", direction: "ltr" },
  { name: "Minangkabau", code: "min", direction: "ltr" },
  { name: "Mizo", code: "lus", direction: "ltr" },
  { name: "Mongolian", code: "mn", direction: "ltr" },
  { name: "Mongolian (Cyrillic)", code: "mn-Cyrl", direction: "ltr" },
  { name: "Mongolian (Traditional)", code: "mn-Mong", direction: "ltr" },
  { name: "Mossi", code: "mos", direction: "ltr" },
  { name: "Myanmar (Burmese)", code: "my", direction: "ltr" },
  { name: "Nepali", code: "ne", direction: "ltr" },
  { name: "Nigerian Fulfulde", code: "fuv", direction: "ltr" },
  { name: "Norwegian (Bokmål)", code: "no", direction: "ltr" },
  { name: "Norwegian (Nynorsk)", code: "nn", direction: "ltr" },
  { name: "Nuer", code: "nus", direction: "ltr" },
  { name: "Nyanja", code: "nya", direction: "ltr" },
  { name: "Nyanja (Chichewa)", code: "ny", direction: "ltr" },
  { name: "Occitan", code: "oc", direction: "ltr" },
  { name: "Odia (Oriya)", code: "or", direction: "ltr" },
  { name: "Oromo", code: "om", direction: "ltr" },
  { name: "Oromo (West Central)", code: "gaz", direction: "ltr" },
  { name: "Pangasinan", code: "pag", direction: "ltr" },
  { name: "Papiamento", code: "pap", direction: "ltr" },
  { name: "Pashto", code: "ps", direction: "ltr" },
  { name: "Polish", code: "pl", direction: "ltr" },
  { name: "Pashto (Southern)", code: "pbt", direction: "ltr" },
  { name: "Plateau Malagasy", code: "plt", direction: "ltr" },
  { name: "Portuguese (Brazil)", code: "pt", direction: "ltr" },
  { name: "Portuguese (Portugal)", code: "pt-PT", direction: "ltr" },
  { name: "Punjabi", code: "pa", direction: "ltr" },
  { name: "Quechua", code: "qu", direction: "ltr" },
  { name: "Queretaro Otomi", code: "otq", direction: "ltr" },
  { name: "Romanian", code: "ro", direction: "ltr" },
  { name: "Rundi", code: "rn", direction: "ltr" },
  { name: "Russian", code: "ru", direction: "ltr" },
  { name: "Shona", code: "sn", direction: "" },
  { name: "Samoan", code: "sm", direction: "ltr" },
  { name: "Sango", code: "sg", direction: "ltr" },
  { name: "Sanskrit", code: "sa", direction: "ltr" },
  { name: "Santali", code: "sat", direction: "ltr" },
  { name: "Sardinian", code: "sc", direction: "ltr" },
  { name: "Scots Gaelic", code: "gd", direction: "ltr" },
  { name: "Sepedi", code: "nso", direction: "ltr" },
  { name: "Serbian", code: "sr", direction: "ltr" },
  { name: "Serbian", code: "sr", direction: "ltr" },
  { name: "Serbian (Cyrillic)", code: "sr-Cyrl", direction: "ltr" },
  { name: "Serbian (Latin)", code: "sr-Latn", direction: "ltr" },
  { name: "Sesotho", code: "st", direction: "ltr" },
  { name: "Sesotho sa Leboa", code: "nso", direction: "ltr" },
  { name: "Shan", code: "shn", direction: "ltr" },
  { name: "Sicilian", code: "scn", direction: "ltr" },
  { name: "Silesian", code: "szl", direction: "ltr" },
  { name: "Sindhi", code: "sd", direction: "ltr" },
  { name: "Sinhala (Sinhalese)", code: "si", direction: "ltr" },
  { name: "Slovak", code: "sk", direction: "ltr" },
  { name: "Slovenian", code: "sl", direction: "ltr" },
  { name: "Somali", code: "so", direction: "ltr" },
  { name: "Somali (Arabic)", code: "so", direction: "ltr" },
  { name: "Sotho (Northern)", code: "nso", direction: "ltr" },
  { name: "Sotho (Southern)", code: "st", direction: "ltr" },
  { name: "Spanish", code: "es", direction: "ltr" },
  { name: "Spanish (Latin America)", code: "es-419", direction: "ltr" },
  { name: "Spanish (Mexico)", code: "es-MX", direction: "ltr" },
  { name: "Standard Latvian", code: "lvs", direction: "ltr" },
  { name: "Standard Malay", code: "zsm", direction: "ltr" },
  { name: "Sundanese", code: "su", direction: "ltr" },
  { name: "Swahili", code: "sw", direction: "ltr" },
  { name: "Swati", code: "ss", direction: "ltr" },
  { name: "Swedish", code: "sv", direction: "ltr" },
  { name: "Tahitian", code: "ty", direction: "ltr" },
  { name: "Tajik", code: "tg", direction: "ltr" },
  { name: "Tamasheq", code: "taq", direction: "ltr" },
  { name: "Tamazight, Central Atlas", code: "tzm", direction: "ltr" },
  { name: "Tamil", code: "ta", direction: "ltr" },
  { name: "Tatar (Latin)", code: "tt", direction: "ltr" },
  { name: "Tatar / Tartar", code: "tt", direction: "ltr" },
  { name: "Telugu", code: "te", direction: "ltr" },
  { name: "Thai", code: "th", direction: "ltr" },
  { name: "Tibetan", code: "bo", direction: "ltr" },
  { name: "Tigrinya", code: "ti", direction: "ltr" },
  { name: "Tok Pisin", code: "tpi", direction: "ltr" },
  { name: "Tongan", code: "to", direction: "ltr" },
  { name: "Tosk Albanian", code: "als", direction: "ltr" },
  { name: "Tsonga", code: "ts", direction: "ltr" },
  { name: "Tswana / Setswana", code: "tn", direction: "ltr" },
  { name: "Tumbuka", code: "tum", direction: "ltr" },
  { name: "Turkish", code: "tr", direction: "ltr" },
  { name: "Turkmen", code: "tk", direction: "ltr" },
  { name: "Twi", code: "tw", direction: "ltr" },
  { name: "Twi (Akan)", code: "ak", direction: "ltr" },
  { name: "Ukrainian", code: "uk", direction: "ltr" },
  { name: "Umbundu", code: "umb", direction: "ltr" },
  { name: "Upper Sorbian", code: "hsb", direction: "ltr" },
  { name: "Urdu", code: "ur", direction: "rtl" },
  { name: "Uyghur", code: "ug", direction: "rtl" },
  { name: "Uzbek (Latin)", code: "uz", direction: "ltr" },
  { name: "Uzbek (Northern)", code: "uzn", direction: "ltr" },
  { name: "Venetian", code: "vec", direction: "ltr" },
  { name: "Vietnamese", code: "vi", direction: "ltr" },
  { name: "Waray (Philippines)", code: "war", direction: "ltr" },
  { name: "Welsh", code: "cy", direction: "ltr" },
  { name: "Wolof", code: "wo", direction: "ltr" },
  { name: "Xhosa", code: "xh", direction: "ltr" },
  { name: "Yiddish", code: "yi", direction: "ltr" },
  { name: "Yiddish (Eastern)", code: "ydd", direction: "ltr" },
  { name: "Yoruba", code: "yo", direction: "ltr" },
  { name: "Yucatec Maya", code: "yua", direction: "ltr" },
  { name: "Zulu", code: "zu", direction: "ltr" },
];
