import React from "react";
import { Tooltip } from "react-tooltip";

const DownloadText = ({ id }) => {
  const handleDownload = () => {
    const content = document.getElementById(id).innerText;
    const blob = new Blob([content], { type: "text/plain" });
    const link = document.createElement("a");
    link.download = "file";
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <svg
        data-tooltip-id="download-tooltip"
        data-tooltip-content="Download"
        onClick={handleDownload}
        className="cursor-pointer outline-none"
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="15"
        viewBox="0 0 17 15"
        fill="none"
      >
        <path
          d="M2.72549 14.8571C2.18466 14.8571 1.66598 14.6422 1.28355 14.2598C0.901125 13.8774 0.686279 13.3587 0.686279 12.8179V9.90471C0.686279 9.67293 0.778356 9.45063 0.942253 9.28674C1.10615 9.12284 1.32844 9.03076 1.56023 9.03076C1.79201 9.03076 2.01431 9.12284 2.1782 9.28674C2.3421 9.45063 2.43418 9.67293 2.43418 9.90471V12.8179C2.43418 12.9787 2.56469 13.1092 2.72549 13.1092H14.9608C15.0381 13.1092 15.1121 13.0785 15.1668 13.0239C15.2214 12.9692 15.2521 12.8951 15.2521 12.8179V9.90471C15.2521 9.67293 15.3442 9.45063 15.5081 9.28674C15.672 9.12284 15.8943 9.03076 16.1261 9.03076C16.3578 9.03076 16.5801 9.12284 16.744 9.28674C16.9079 9.45063 17 9.67293 17 9.90471V12.8179C17 13.3587 16.7852 13.8774 16.4027 14.2598C16.0203 14.6422 15.5016 14.8571 14.9608 14.8571H2.72549Z"
          fill="#5B93FF"
        />
        <path
          d="M7.96919 7.50315V0.87395C7.96919 0.642164 8.06126 0.419871 8.22516 0.255974C8.38906 0.0920766 8.61135 0 8.84314 0C9.07492 0 9.29721 0.0920766 9.46111 0.255974C9.62501 0.419871 9.71709 0.642164 9.71709 0.87395V7.50315L12.0127 5.20874C12.0938 5.12764 12.19 5.0633 12.296 5.01941C12.402 4.97552 12.5156 4.95292 12.6303 4.95292C12.7449 4.95292 12.8585 4.97552 12.9645 5.01941C13.0705 5.0633 13.1667 5.12764 13.2478 5.20874C13.3289 5.28984 13.3933 5.38613 13.4372 5.49209C13.4811 5.59806 13.5037 5.71163 13.5037 5.82633C13.5037 5.94103 13.4811 6.0546 13.4372 6.16057C13.3933 6.26653 13.3289 6.36282 13.2478 6.44392L9.46073 10.231C9.37967 10.3122 9.2834 10.3766 9.17742 10.4206C9.07145 10.4645 8.95786 10.4871 8.84314 10.4871C8.72842 10.4871 8.61482 10.4645 8.50885 10.4206C8.40288 10.3766 8.30661 10.3122 8.22555 10.231L4.43843 6.44392C4.35733 6.36282 4.29299 6.26653 4.2491 6.16057C4.20521 6.0546 4.18262 5.94103 4.18262 5.82633C4.18262 5.71163 4.20521 5.59806 4.2491 5.49209C4.29299 5.38613 4.35733 5.28984 4.43843 5.20874C4.51953 5.12764 4.61582 5.0633 4.72179 5.01941C4.82775 4.97552 4.94133 4.95292 5.05602 4.95292C5.17072 4.95292 5.28429 4.97552 5.39026 5.01941C5.49623 5.0633 5.59251 5.12764 5.67361 5.20874L7.96919 7.50315Z"
          fill="#5B93FF"
        />
      </svg>
      <Tooltip
        id="download-tooltip"
        place="top"
        multiline={true}
        className="z-50"
      />
    </>
  );
};

export default DownloadText;
