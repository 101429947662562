import { useLocation } from "@reach/router";
import { StyleGuideToolFaqData } from "components/FAQ/config";
import Layout from "components/layout";
import ToolFaq from "components/QualityTools/ToolFaq";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useEffect, useRef, useState } from "react";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import { Tooltip } from "react-tooltip";
import { navigate } from "gatsby";
import CleanButton from "assets/cleanBtn.svg";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import Loader from "components/loader";

const Result = () => {
  const [sourceText, setSourceText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [error, setError] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const [inputLang, setInputLang] = useState("");
  const textareaRef = useRef(null);
  const outputRef = useRef(null);

  const handleTextChange = (e) => {
    const text = e.target.value;

    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);

    if (words.length > 1000) {
      e.target.value = words.slice(0, 1000).join(" ");
      return;
    }

    setWordCount(words.length);
    setSourceText(text);
    setOutputText("");
    adjustHeight();
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 380);
    textarea.style.height = `${newHeight}px`;
  };
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");
  const displayShareId = UpdatreshareId;

  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        const { user_text: sourceText, source_language_code: languagecode } =
          response.meta;

        setSourceText(sourceText);
        setInputLang(languagecode);
        setOutputText(response?.result);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoader(false);
      }
    };
    fatchAnalysis();
  }, []);
  const copyText = () => {
    if (outputRef.current) {
      const textToCopy = outputRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };

  const styleGuideEntries = outputText
    ? Object.entries(outputText).map(([term, definition]) => ({
        Term: term,
        Definition: definition,
      }))
    : [];
  const downloadText = () => {
    if (styleGuideEntries && styleGuideEntries.length > 0) {
      const header = "Term,Definition\n";
      const rows = styleGuideEntries
        .map((item) => {
          const term = item.Term.replace(/,/g, "");
          const definition = item.Definition.replace(/,/g, "").replace(
            /\n/g,
            " "
          );
          return `"${term}","${definition}"`;
        })
        .join("\n");

      const csvContent = header + rows;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "style-guide.csv";
      link.click();
    }
  };
  if (loader)
    return (
      <div className="grid place-content-center h-[calc(100vh-150px)]">
        <Loader />
      </div>
    );
  return (
    <Layout>
      <SEO
        title="Translation Style Guide Generator"
        description="Create detailed style guides for your translation projects with our AI Style Guide Generator. Keep tone, terminology, and formatting consistent."
        slug="/tools/style-guide-generator"
      />
      <div>
        <div className="w-full py-7 ">
          <ToolsBreadcrumb />
        </div>

        <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] lg:pb-[60px] pt-12 pb-8 ">
          <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
            Style Guide Generator
          </h1>
          <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
            Generate an AI Style Guide to Save Time and Reduce Errors
          </h2>

          <div className="mt-12 flex lg:flex-row flex-col gap-4">
            <div className="relative shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans">
              <div className="flex justify-between">
                <span className="font-bold text-lg text-[#6E6E6E]">
                  {" "}
                  Source Text
                </span>
                {sourceText && (
                  <img
                    src={CleanButton}
                    className="cursor-pointer"
                    alt="clean-text-button"
                    onClick={() => {
                      navigate(`/tools/style-guide-generator/`);
                    }}
                  />
                )}
              </div>

              <hr className="border border-[#B0B0B0] mt-2 mb-4" />

              <textarea
                dir={
                  inputLang === "fa" || inputLang === "he" || inputLang === "ar"
                    ? "rtl"
                    : "ltr"
                }
                ref={textareaRef}
                value={sourceText}
                dangerouslySetInnerHTML={{
                  __html: sourceText.replace(/\n/g, "<br />"),
                }}
                onChange={handleTextChange}
                className={`anonymize_tool  outline-none w-full resize-none text-base border-none min-h-[300px]`}
                placeholder="Enter your text here"
              />

              <div
                className={`mt-5 w-full bg-white  ${
                  outputText ? "lg:absolute  bottom-0 left-0 p-4 " : ""
                }`}
              >
                <div className="flex md:flex-row items-end flex-col gap-2.5 justify-between">
                  <div className="flex-1">
                    <p className="text-base text-[#9C9C9C] ">
                      {wordCount} words
                    </p>
                  </div>
                  <button
                    disabled={outputText || !sourceText}
                    className={` outline-none font-opensans font-semibold text-base flex items-center justify-center px-6 py-3 rounded-lg gap-3 border ${
                      !outputText
                        ? "bg-[#5B93FF] border-[#5B93FF]  text-white"
                        : "bg-[#E8E8E8] border-[#E8E8E8] "
                    }`}
                  >
                    {loader ? <Loader /> : "Generate Style Guide"}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`relative shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans ${
                outputText && "border border-[#5B93FF]"
              }`}
            >
              <div className="flex justify-between items-center">
                <span
                  className={`font-bold text-lg ${
                    outputText ? "text-[#6E6E6E]" : "text-[#D2D2D2]"
                  }`}
                >
                  Style Guide
                </span>
                <div className="flex gap-3">
                  <button
                    data-tooltip-id="copy-tooltip"
                    data-tooltip-content={copySuccess}
                    onClick={copyText}
                    className=""
                  >
                    <img src={CopyIcon} alt="/copyicons" />
                  </button>
                  <button
                    data-tooltip-id="download-tooltip"
                    data-tooltip-content="Download"
                    onClick={downloadText}
                    className=""
                  >
                    <img src={DownloadIcon} alt="/downloadicon" />
                  </button>

                  <Tooltip
                    id="copy-tooltip"
                    place="top"
                    multiline={true}
                    className="z-50"
                  />
                  <Tooltip
                    id="download-tooltip"
                    place="top"
                    multiline={true}
                    className="z-50"
                  />
                </div>
              </div>
              <hr
                className={`border mt-2  ${
                  outputText ? "border-[#B0B0B0]" : "border-[#D2D2D2]"
                }`}
              />

              <div
                ref={outputRef}
                className={`flex items-center justify-center  flex-col w-full overflow-y-auto overflow-x-hidden anonymize_tool  border-none pr-2 ${
                  outputRef?.current?.offsetHeight
                    ? `min-h-[${outputRef?.current?.offsetHeight + 54}px]`
                    : outputText
                    ? "h-full "
                    : "min-h-[225px]"
                }  ${outputRef ? "max-h-[500px]" : "max-h-[400px]"} `}
              >
                {outputText ? (
                  <div
                    className="flex flex-col gap-2.5 w-full  pb-20"
                    id=""
                  >
                    {styleGuideEntries &&
                      styleGuideEntries.map((item, index) => (
                        <li className="flex" key={index}>
                        <p className="p-3 w-full lg:max-w-[200px] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                        {item.Term}
                        </p>
                        <p className="p-3 bg-[#F0F5FF] w-full  border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                        {item.Definition}
                        </p>
                      </li>
                        // <div
                        //   key={index}
                        //   className={`font-opensans rounded-lg bg-[#F0F5FF] p-4 w-full`}
                        // >
                        //   <span
                        //     className={`text-base  text-[#858585] font-opensans font-bold`}
                        //   >
                        //     {item.Term}
                        //   </span>
                        //   <p className="text-[#424242] text-sm mt-2 font-opensans font-normal">
                        //     {item.Definition}
                        //   </p>
                        // </div>
                      ))}
                  </div>
                ) : (
                  <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
                    {error?.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans">
            Enter your text or project details and the tool generates a style
            guide to ensure consistency and accuracy in your translations. This
            helps streamline your workflow and deliver professional results.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={StyleGuideToolFaqData} />
    </Layout>
  );
};

export default Result;
