import { useLocation } from "@reach/router";
import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBanner from "components/commontools/toolsBanner";
import Layout from "components/layout";
import QualityDifficultyOutput from "components/QualityTools/Qualitydifficult";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import { Link, navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import ResetButton from "assets/resetbutton.jpg";
import CleanButton from "assets/cleanBtn.svg";
import Loader from "components/loader";
import ToolFaq from "components/QualityTools/ToolFaq";
import { QaToolFaqData } from "components/FAQ/config";

const Result = () => {
    const [divHeight, setDivHeight] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showOutput, setShowOutput] = useState(false);
    const [inputText, setInputText] = useState("");
    const refDiv = useRef(null);
    const [analysisResult, setAnalysisResult] = useState(null);
    const [wordCountInput, setWordCountInput] = useState(0);
    const [shareId, setShareId] = useState("");
     const [inputLang, setInputLang] = useState("");
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const UpdatreshareId = params.get("share-id");
    const displayShareId = shareId ? shareId : UpdatreshareId;

    // useEffect(() => {
        if (refDiv.current) {
          setDivHeight(refDiv.current?.offsetHeight);
        }
    //   }, [refDiv,displayShareId]);
    

    useEffect(() => {
      if (!displayShareId) return;
      const fatchAnalysis = async () => {

        try {
          const response = await http().get(
            `${endpoints.forms.aiTools}/${displayShareId}`
          );

          setAnalysisResult(response);
          setShowOutput(true);
  
          const { source_language_code: sourceLangCode, user_text: sourceText } =
            response.meta;
  
          setInputLang(sourceLangCode)
          setInputText(sourceText);

        } catch (err) {
          setError(err.message || "An unexpected error occurred.");
        } finally {
          setLoading(false);
        }
      };
      fatchAnalysis();
    }, []);
    const handleClearText = () => {       
           navigate(`/tools/translation-difficulty-checker/`)   
        };
        const isButtonDisabled =  !inputText;
      
        if (loading)
            return (
              <div className="grid place-content-center h-[calc(100vh-150px)]">
                <Loader />
              </div>
            );
  return (
    <Layout>
      <SEO
        title="Translation Difficulty Checker"
        description="Evaluate your text's complexity with our translation difficulty checker to determine if you need machine translation, editing, or human translation.​"
        slug="/tools/translation-difficulty-checker"
      />
      <div className="bg-[#F5F5F5]">
        <div className="pt-5">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Translation Difficulty Checker"
            title="Identify the Best Translation Method for Your Text"
            description="Input text in any language, and the tool will analyze its complexity. You'll get a recommendation on whether to use raw machine translation, MTPE, or human translation. This makes it easier to choose the right translation method for your text, improving quality and efficiency."
          />
      
          <div className="lg:flex gap-3 mt-12">
            <div  className="flex flex-col gap-3 w-full lg:w-[60%]">
              <div className="lg:flex items-center  rounded-lg shadow-lg ">
                <ToolInput
                    lang={inputLang}
                    text={inputText}
                    wordCount={wordCountInput}
                    setText={setInputText}
                    // setOutputText={setOutputText}
                    setAnalysisResult={setAnalysisResult}
                    setShowOutput={setShowOutput}
                    setWordCount={setWordCountInput}
                    iscleanBtn={true}
                    isDifficulty={true}
                    refDiv={refDiv}
                    result={analysisResult}
                    divHeight={divHeight}
                    handleClearText={handleClearText}
                  placeholder="Enter your source text here"
                >
                  {" "}
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                    <p className=" text-[#6E6E6E] font-opensans font-bold">
                      Source text
                    </p>
                    {inputText && (
                      <img
                        src={CleanButton}
                        alt="clean-button"
                        onClick={handleClearText}
                        className="cursor-pointer  absolute right-[14px]"
                      />
                    )}
                  </div>
                </ToolInput>
              </div>
            </div>

            <ToolOutput
                // handleClick={handleClick}
                result={analysisResult?.result}
                isButtonDisabled={isButtonDisabled}
                ResetButton={ResetButton}
                loading={loading}
                // handleReset={handleReset}
                showOutput={showOutput}
                error={error}
                // ref={refDiv}
                isDifficulty={true}
              buttontext="Check Complexity"
              text='Your results will appear here. <br/> Please enter your texts and click "Check quality" to begin.'
            >
              <QualityDifficultyOutput
              setDivHeight={setDivHeight}
            //   setShowDetailAnalysis={setShowDetailAnalysis}
              analysisResult={analysisResult?.result}
              refDiv={refDiv}
              isDifficulty={true}
              />
            </ToolOutput>
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          {analysisResult && (
            <div className="bg-[#F0F5FF] p-6 rounded-lg mt-6 lg:flex justify-between gap-6">
              <div>
                <h2 className="text-2xl font-primary text-[#0A2641] font-bold">
                  Need help translating?
                </h2>
                <p className="text-[#424242] pt-3 leading-6 font-base font-opensans ">
                  If your translation needs improvement, reach out to Tomedes
                  for expert translation, editing, and proofreading services.
                </p>
              </div>
              <Link to="/contactus.php">
                <button className="flex mt-4 md:mt-0 items-center gap-2 bg-[#0A2641] px-6 py-4 text-white rounded-lg">
                  Contact us
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M1 12.75H23M23 12.75L14.75 4.5M23 12.75L14.75 21"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </Link>
            </div>
          )}
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
            Input text in any language, and the tool will analyze its
            complexity. You'll get a recommendation on whether to use raw
            machine translation, MTPE, or human translation. This makes it
            easier to choose the right translation method for your text,
            improving quality and efficiency.
          </p>
        </div>
      </div>
      <div>
        <ToolFaq QaToolFaqData={QaToolFaqData} />
      </div>
    </Layout>
  );
};

export default Result;
