

import Layout from "components/layout";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useEffect,  useState } from "react";
import TranscriptionTool from "components/TranscriptionTool/TranscriptionTool";
import Uploadfiles from "components/TranscriptionTool/Uploadfiles";
import ToolFaq from "components/QualityTools/ToolFaq";
import {TranscriptionToolFaqData } from "components/FAQ/config";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { navigate } from "gatsby";
import SEO from "components/seo";
import { useLocation } from "@reach/router";

const Index = () => {
  const [files, setFiles] = useState("");
  const [loader,setLoader] = useState(false)
  const [error,setError] = useState(false)
 
  const location = useLocation()
  const query = new URLSearchParams(location.search);
  const newFile = query.get("new_file")

  
  const onFileUpload = (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    setFiles(file);
    formData.append("file", e.target.files[0]);
  
  };
  useEffect(() => {
    if (newFile) {
      const fileInput = document.getElementById("file"); 
      if (fileInput) {
        
        fileInput.click(); 
      }
    }
  }, [newFile]);
  const fileSize = (fileSizeInBytes) => {
    
    const fileSizeInKB = fileSizeInBytes / 1024;
    if (fileSizeInKB < 1024) {
     
      return `${fileSizeInKB.toFixed(2)} KB`;
    } else {
     
      const fileSizeInMB = fileSizeInKB / 1024;
      return `${fileSizeInMB.toFixed(2)} MB`;
    }
  };

  useEffect(()=>{
  const fatchfile = async() =>{
    if (!files || files?.size > 25 * 1024 * 1024) 
      return;
    
    setLoader(true)
    try {
      const formData = new FormData();
      formData.append("audio", files);
      const response = await http().post(endpoints.forms.transacription,formData );

      const newShareId = response?.meta?.share_id;
 
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(
        `/tools/ai-transcription/result?share-id=${newShareId}`
      );
    } catch (error) {
      console.error("Error during quality analysis:", error);
      setError(error?.message?.audio[0])
    } finally {
      setLoader(false);
    }

  }
  fatchfile()
},[files])

  return (
    <Layout>
         <SEO
        title="Free Audio Video AI Transcription"
        description="Convert audio and video files to text instantly with the Tomedes AI Transcription Tool. Supports nearly 100 languages and multiple file formats. Fast, accurate, and free"
        slug="/tools/ai-transcription"
      />
      <div>
        <div className="w-full py-7 ">
          <ToolsBreadcrumb />
          <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] lg:pb-[60px] pt-12 pb-8 ">
            <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
              AI Transcription
            </h1>
            <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
            Turn Audio into Text: Save Time, Share Ideas, and Simplify Your Workflow
            </h2>
            <div className="mt-12 flex lg:flex-row flex-col gap-4">
              <Uploadfiles files={files} setFiles={setFiles} onFileUpload ={onFileUpload } setLoader={setLoader} fileSize={fileSize} setError={setError}      uploadtext="Upload file"
                   text="Supported files: mp3, mp4, mpeg, mpga, m4a, wav, and webm.<br/>
                         Maximum file upload size: 25mb"/>
              <TranscriptionTool  loader={loader} error={error} />
            </div>
          </div>
          <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 max-w-5xl mx-auto md:mt-12 text-lg text-center font-opensans">
          This Image to Text Converter transforms static images into editable, searchable text with just a click. Upload your PNG, JPEG, WEBP, HEIC or HEIF files, and let AI do the rest. Ideal for extracting notes, digitizing printed documents, or capturing text from visuals, this tool helps you save time, reduce manual effort, and focus on what matters most.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={TranscriptionToolFaqData} />
    </Layout>
  );
};

export default Index;

