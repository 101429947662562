import React, { useEffect, useState } from "react";
import Hero from "assets/carrier/jobopeninghero.webp";
import Layout from "components/layout";
import Summary_icon from "assets/summary_icon.svg";
import Detect_icon from "assets/detect_icon.svg";
import Translation_icon from "assets/translation_icon.svg";
import { Link } from "gatsby";
import Arrow from "assets/rightarrow.svg";
import SEO from "components/seo";
import Wordcount from "assets/word_count.svg";
import DataAnonymizationTool from "assets/data-anonymization.svg";
import TopicTool from "assets/topic.svg";
import HERO_BANNER from "assets/toolsbanner.svg";
import ConsistencyIcon from "assets/consistency.svg";
import Glossary from "assets/glossary.svg";
import Difficulty from "assets/Translation_Difficulty_Checker.svg";
import ContextualIcon from "assets/contextual.svg";
import SourceIcon from "assets/sourceTextIcon.svg";
import PreTranslationIcon from "assets/pre-translation.svg";
import StyleGuide from "assets/styleguide.svg";
import WordCounterIcon from "assets/wordCounterIcon.svg";
import Click from "assets/greenClick.svg";
import Transcription from "assets/transc.svg";
import ImageText from "assets/imagetext.svg";
import Translator from "assets/translator.svg";
import EditingIcon from "assets/editingIcon.svg";
import Textedition from "assets/textedition.svg"
const Tools = () => {
  const title = "Free AI translation tools";
  const description =
    " Discover the best free AI tools for translations to streamline pre and post-translation tasks, ensuring accuracy and professional results.";

  const backgroundImageStyle = {
    backgroundImage: `url(${HERO_BANNER})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const toolsData = [
    {
      imgurl: EditingIcon,
      title: "AI Translator",
      description:
        "Experience the power of translation aggregation with the Free AI Translator Tool. Compare results from leading AI and LLM sources, access quality scores, and get insights to ensure the best translations.",
      link: "/tools/ai-translator",
      key: "all",
    },
    {
      imgurl: PreTranslationIcon,
      title: "Pre Translation Toolkit",
      description:
        "Helps you prepare for translation by identifying potential challenges and other insights, ensuring accuracy, and improving efficiency",
      link: "/tools/pre-translation",
      key: "pre",
    },
    {
      imgurl: Transcription,
      title: "Transcription Tool",
      description:
        "Convert audio and video files to text instantly with the Tomedes AI Transcription Tool. Supports nearly 100 languages and multiple file formats. Fast, accurate, and free.",
      link: "/tools/ai-transcription",
      key: "all",
    },

    {
      imgurl: ImageText,
      title: "Image to Text Converter",
      description:
        "Convert images into editable text instantly with the Tomedes Image to Text Converter. Supports PNG, JPEG, and other formats. Fast, accurate, and free.",
      link: "/tools/image-to-text",
      key: "all",
    },
    {
      imgurl: Glossary,
      title: "Bilingual Glossary Generator",
      description:
        "Generate bilingual glossaries instantly with our free AI-powered tool. It's the ideal solution for quickly creating glossaries tailored to your project.",
      link: "/tools/glossary-generator",
      key: "post",
    },
    {
      imgurl: Textedition,
      title: "Text Editing Tool",
      description:
        "Edit text with the AI-Powered Text Editing Tool. Input custom instructions to refine text in multiple languages with precision and speed.",
      link: "/tools/ai-writing-assistant",
      key: "all",
    },
    {
      imgurl: Difficulty,
      title: "Translation Difficulty Checker",
      description:
        "Helps decide the best translation method for each project, improving quality and efficiency.",
      link: "/tools/translation-difficulty-checker",
      key: "pre",
    },
    {
      imgurl: ConsistencyIcon,
      title: "Consistency Checker",
      description:
        "Automatically detects inconsistent terminology to improve translation accuracy and coherence.",
      link: "/tools/translation-consistency-checker",
      key: "post",
    },
    {
      imgurl: TopicTool,
      title: "Topic Detection Tool",
      description:
        "Identifies the main topic of a text and helps users match it to a translator with the right expertise.",
      link: "/tools/topic-detection",
      key: "pre",
    },

    {
      imgurl: Translation_icon,
      title: "Translation Quality Assurance Tool",
      description:
        "Quickly identifies quality issues, enabling faster revisions and avoiding customer complaints after delivery.",
      link: "/tools/translation-quality-assurance",
      key: "post",
    },
    {
      imgurl: Summary_icon,
      title: "Translation Guideline Adherence Checker",
      description:
        "Compares source text, translated text, and guidelines to ensure translation quality and reduce manual reviews.",
      link: "/tools/guideline-adherence-checker",
      key: "post",
    },
    {
      imgurl: DataAnonymizationTool,
      title: "Data Anonymization Tool",
      description: "Redacts confidential information...",
      link: "/tools/data-anonymization",
      key: "pre",
    },
    {
      imgurl: SourceIcon,
      title: "Source Text Checker",
      description:
        "Insert a short description here. To be replaced once content is available.",
      link: "/tools/source-text-checker",
      key: "pre",
    },
    {
      imgurl: StyleGuide,
      title: "Style Guide Generator",
      description:
        "Insert a short description here. To be replaced once content is available.",
      link: "/tools/style-guide-generator",
      key: "pre",
    },
    {
      imgurl: ContextualIcon,
      title: "Contextual Glossary Generator",
      description:
        "Create a tailored glossary with the Contextual Glossary Generator. Analyze any text to find key terms and definitions for an industry-specific reference guide.",
      link: "/tools/contextual-glossary-generator",
      key: "pre",
    },
    {
      imgurl: Detect_icon,
      title: "Language Detector",
      description:
        "Instantly detects the language of any text, simplifying the translation process.",
      link: "/tools/language-detector",
      key: "common",
    },
    {
      imgurl: Wordcount,
      title: "Word Count Ratio Tool",
      description:
        "Estimates word count changes between source and target languages to help plan translation time and costs.",
      link: "/tools/word-count-ratio",
      key: "pre",
    },

    {
      imgurl: Summary_icon,
      title: "Text Summarizer",
      description: "Quickly generates concise summaries...",
      link: "/tools/text-summarizer",
      key: "pre",
    },

    {
      imgurl: WordCounterIcon,
      title: "Free AI Word Counter",
      description:
        "Effortlessly analyze multilingual text with the Tomedes Word Count Tool. Detect languages, count words and characters, and receive precise results tailored for each language.",
      link: "/tools/word-counter",
      key: "pre",
    },
  ];

  const filterTab = [
    { title: "All tools", key: "all" },
    { title: "Pre-translation", key: "pre" },
    {
      title: "Translation",
      key: "translate",
      link: "/tools/ai-translator",
      imgUrl: Translator,
    },
    {
      title: "Transcription Tool",
      link: "/tools/ai-transcription",
      key: "",
    },
    {
      title: "Image to Text Converter",
      link: "/tools/image-to-text",
      key: "",
    },
    { title: "Post-translation", key: "post" },
  ];

  const [toolsList, setToolsList] = useState(toolsData);
  const [activeTab, setActiveTab] = useState("all");
  const [open, setOpen] = useState(false);

  const handleTab = (key) => {
    setActiveTab(key);
    setToolsList(
      key === "all" || key === "common"
        ? toolsData
        : toolsData.filter((tool) => tool.key === key || tool?.key === "common")
    );
  };

  return (
    <Layout>
      <SEO title={title} description={description} slug="/tools" />

      <div className="bg-white flex md:flex-row flex-col max-w-7xl mx-auto md:py-20 py-5 md:gap-12 gap-6 md:px-6 px-7">
        <div className="flex flex-col gap-4 md:w-[276px] w-full">
          <h1 className="font-bold text-2xl font-primary">
            Free AI translation tools
          </h1>
          <p className="font-opensans text-base md:text-lg font-normal leading-7">
            Enhance translation workflow with free AI tools designed to optimize
            every stage of the translation process.
          </p>

          <div className="mt-10  flex-col gap-2 md:flex hidden ">
            {filterTab.map((tab) => (
              <Link to={tab.link} key={tab.key}>
                <button
                  onClick={() => handleTab(tab.key)}
                  key={tab.key}
                  className={`px-3 py-2 font-bold font-opensans flex gap-1 items-center text-sm w-full text-start rounded ${
                    activeTab === tab.key
                      ? "bg-[#7AA7FF] text-[#F5F5F5]"
                      : "bg-transparent text-[#6E6E6E]"
                  }`}
                >
                  {tab.title}
                  {/* {tab.imgUrl && <img src={tab.imgUrl} alt="Icon" className="" />} */}
                </button>
              </Link>
            ))}
          </div>

          <div className="items-center gap-3 mt-2 md:hidden flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M9.64835 16H5.88364C5.69719 15.9976 5.51906 15.9224 5.3872 15.7906C5.25535 15.6587 5.1802 15.4806 5.17776 15.2941V8L0.142466 1.12C0.0628812 1.01653 0.0144182 0.892535 0.00275053 0.762523C-0.00891719 0.632512 0.0166931 0.501867 0.0765833 0.385882C0.136582 0.270232 0.227029 0.173164 0.33816 0.105159C0.449291 0.0371529 0.576886 0.000791718 0.707172 0H14.8248C14.9551 0.000791718 15.0827 0.0371529 15.1938 0.105159C15.305 0.173164 15.3954 0.270232 15.4554 0.385882C15.5153 0.501867 15.5409 0.632512 15.5292 0.762523C15.5176 0.892535 15.4691 1.01653 15.3895 1.12L10.3542 8V15.2941C10.3518 15.4806 10.2766 15.6587 10.1448 15.7906C10.0129 15.9224 9.8348 15.9976 9.64835 16ZM6.58952 14.5882H8.94247V7.76471C8.94098 7.61461 8.99078 7.46852 9.08364 7.35059L13.4131 1.41176H2.11894L6.46717 7.35059C6.56003 7.46852 6.60984 7.61461 6.60835 7.76471L6.58952 14.5882Z"
                fill="#0A2641"
              />
            </svg>
            <div
              onClick={() => setOpen(!open)}
              className="h-[48px] p-3 bg-white relative rounded-lg text-base font-semibold font-opensans border w-full border-solid border-quotetext-100 text-[#777777] flex items-center justify-between"
            >
              <span>
                {activeTab === "all"
                  ? "All tools"
                  : activeTab === "pre"
                  ? "Pre-translation"
                  : "Post-translation"}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
              >
                <path
                  d="M10.9991 1.53198L6.06303 6.46806L1.12695 1.53198"
                  stroke="#202020"
                  strokeWidth="1.48082"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {open && (
                <div className="absolute top-[48px] bg-white  left-0 right-0 rounded overflow-hidden pb-1">
                  {filterTab.map((tab) => (
                    <Link to={tab.link} key={tab.key}>
                      <button
                        onClick={() => handleTab(tab.key)}
                        className={`px-3 py-2 font-bold flex gap-1 items-center font-opensans text-sm w-full text-start ${
                          activeTab === tab.key
                            ? "bg-[#E0EBFF] text-[#5B93FF]"
                            : "bg-transparent text-[#424242]"
                        }`}
                      >
                        {tab.title}
                        {/* {tab.imgUrl && <img src={tab.imgUrl} alt="Icon" className="" />} */}
                      </button>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="mt-8 flex flex-col gap-2 text-[#858585] font-opensans text-sm">
            <p className="flex items-center gap-2">
              <img src={Click} alt="/click" />
              <span>100% free, no sign up required</span>
            </p>
            <p className="flex items-center gap-2">
              <img src={Click} alt="/click" />
              <span>Committed to data protection</span>
            </p>
          </div>
        </div>

        <div className="flex-1 grid lg:grid-cols-3 gap-[1.5rem]">
          {toolsList.map((item, i) => (
            <Link
              key={i}
              to={item.link}
              className="bg-white p-4 md:p-6 hover:bg-[#F0F5FF] outline-none border border-white md:first-of-type:border-white  md:first-of-type:bg-white  hover:border hover:border-[#5B93FF] hover:first-of-type:border-[#5B93FF] shadow-box rounded-lg hover:first-of-type:bg-[#F0F5FF]"
              // className="bg-white p-4 md:p-6 hover:bg-[#F0F5FF] outline-none border border-white md:first-of-type:border-white first-of-type:border-[#5B93FF] md:first-of-type:bg-white first-of-type:bg-[#F0F5FF] hover:border hover:border-[#5B93FF] hover:first-of-type:border-[#5B93FF] shadow-box rounded-lg hover:first-of-type:bg-[#F0F5FF]"
            >
              <div>
                <img src={item.imgurl} alt="icon" className="w-8 h-6" />
                <p className="mt-2.5 text-[#0A2641] font-opensans leading-6 text-lg font-bold flex gap-2 items-center">
                  {item.title}
                </p>
                <p className="text-[#575757] font-opensans leading-6 font-normal text-base mt-4 line-clamp-2">
                  {item.description}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Tools;
