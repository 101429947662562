import ClockSvg from "assets/svg/clock";
import React from "react";
import OutputHeader from "components/PreTranslation/OutputHeader";
import * as XLSX from "xlsx";

const EditingOutput = ({ analysisResult }) => {
  const handleCopy = () => {
    const text = document.getElementById("editing-output").innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy data: ", error);
      });
  };

  const handleDownloadExcel = () => {
    const glossaryEntries = analysisResult
      ? Object.entries(analysisResult).map(([term, definition]) => ({
          Term: term,
          Definition: definition,
        }))
      : [];
    const data = glossaryEntries.map((item) => ({
      Term: item.Term,
      Definition: item.Definition,
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Glossary");

    XLSX.writeFile(wb, "summary_of_revision.xlsx");
  };

  return (
    <div className="bg-white p-4 rounded-lg">
      <OutputHeader
        title="Detailed summary of revisions"
        downloadData={handleDownloadExcel}
        tooltiptext="A glossary of important terms from your text and their meanings."
        copyText={handleCopy}
      />
      <ul id="editing-output">
        {Object.entries(analysisResult).map(([term, definition]) => (
          <li className="flex my-2" key={term}>
            <p className="p-3 w-full lg:max-w-[300px] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
              {term}
            </p>

            <p className="p-3 bg-[#F0F5FF] w-full border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
              {definition}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EditingOutput;
