
import React, { useEffect, useState } from "react";
import OutputHeader from "./OutputHeader";
import * as XLSX from "xlsx";
import "swiper/css";
import "swiper/css/grid";

import { Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Nexticon from "assets/nexticon.svg";
import Pre from "assets/pre.svg";
import { Languages } from "components/QualityTools/LanguagesData";
import cleanBtn from "assets/cleanBtn.svg"

const ContextualGlossaryOutput = ({
  analysisResult,
  showDownload,
  languagecode,
  title,
  border = true,
  style,
}) => {
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1); 

const languageName = Languages.find((lang) =>  lang?.code === languagecode.source_language_code);
const targetlanguageCode = Languages.find((lang)=>lang.code === languagecode.target_language_code)


const handleCopy = () => {
  const text = document.getElementById("contextual-output").innerText;
  navigator.clipboard
    .writeText(text)
    .then(() => {})
    .catch((error) => {
      console.error("Failed to copy data: ", error);
    });
};

const glossaryEntries = analysisResult
  ? Object.entries(analysisResult).map(([term, definition]) => ({
      Term: term,
      Definition: definition,
    }))
  : [];
 
  const handleDownloadExcel = () => {
    const glossaryData = analysisResult?.Glossary.map(item => {
      if (languagecode.target_language_code) {

        return {
          "Term": item.Term,
          "Translation": item.Translation,
          "Explanation": item.Explanation
        };
      } else {

        return {
          "Source Term": item.Source_Term,
          "Explanation": item.English_Explanation
        };
      }
    });

    const ws = XLSX.utils.json_to_sheet(glossaryData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Glossary");

    XLSX.writeFile(wb, "glossary.xlsx");
  };


const handleSlideChange = (swiper) => {
  const currentIndex = swiper.activeIndex + 1;
  setCurrentPage(currentIndex);
};
 const glossaryResult = analysisResult?.Glossary
useEffect(() => {
  setTotalPages(Math.ceil(glossaryResult?.length / 10));
}, [glossaryResult.length]);


return (
  <div className="shadow-box p-5 rounded-lg mt-5 border border-lightBlue">
    <OutputHeader
      title="Key Terms Glossary"
      downloadData={handleDownloadExcel}
      tooltiptext="A glossary of important terms from your text and their meanings."
      copyText={handleCopy}
    />

    <div
      dir={
        languagecode.source_language_code === "fa" ||
        languagecode.source_language_code === "he" ||
        languagecode.source_language_code === "ar"
          ? "rtl"
          : "ltr"
      }
      id="contextual-output"
      className="grid  gap-y-1 gap-x-3"
    >
      {languagecode?.target_language_code ? (
              <ul className="flex flex-col gap-2.5 ">
         
              <li className="flex">
                 <p className="px-6 py-3 w-[33%] md:w-[25%] bg-[#BDD3FF] border-none outline-none text-[#575757] font-opensans text-center font-normal">
                   {languageName.name}
                 </p>
                 <p className="p-3 w-[33%] md:w-[25%] text-center border-none outline-none font-bold text-[#202020] text-base font-opensans  bg-[#F0F5FF]   whitespace-wrap break-words">
                 {targetlanguageCode.name}
                </p>
                <p className="p-3 bg-[#E0EBFF] w-[34%] md:w-[50%] border-none outline-none text-center text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                  Explanation
                </p>
               </li>
              
               </ul>
      ): (
        <ul className="flex flex-col gap-2.5 ">
         
         <li className="flex">
              <p className="p-3 w-full md:w-[25%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                Term
              </p>
              
              <p className="p-3 bg-[#F0F5FF] w-full  md:w-[75%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
               Explanation
              </p>
            </li>
        
         </ul>
      )}
      {analysisResult?.Glossary.length > 10 ? (
        <Swiper
          // slidesPerView={4}
          grid={{
            rows: 10,
          }}
          spaceBetween={30}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          onSlideChange={handleSlideChange}
          // style={{
          //   // height: "850px",
          //   paddingTop: "0px",
          //   paddingBottom: "80px",
          // }}
          className="mySwiper !h-auto md:!h-[850px] !pb-[80px]  "
          modules={[Grid, Navigation]}
          breakpoints={{
            332: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 1,
              // slidesPerGroup: 1,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 1,
              // slidesPerGroup: 1,
              spaceBetween: 30,
            },
          }}
        >
     
          
          <>
          {languagecode.target_language_code ? (
         <ul className="flex flex-col gap-2.5 ">
         {analysisResult?.Glossary.map((item, index) => (
          <SwiperSlide key={index} className=" lg:!h-12 ">
           <li key={index} className="flex h-full lg:h-[70px]  ">
           <p className="p-3 w-[33%] md:w-[25%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
             <span>{item.Term}</span>
           </p>
           <p className="p-3 w-[33%] md:w-[25%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans  bg-[#F0F5FF]   whitespace-wrap break-words">
             {item.Translation}
           </p>
           <p className="p-3 bg-[#E0EBFF] w-[34%] md:w-[50%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
             {item.Explanation}
           </p>
           {/* <button className="ml-3">
            <img src={cleanBtn} alt="closebutton" />
           </button> */}
         </li>
          {/* <button className="ml-3">
            <img src={cleanBtn} alt="closebutton" />
           </button> */}
         </SwiperSlide>

          ))}
         </ul>
        ) : (
          <>
          {analysisResult?.Glossary.map((item, index) => (
             <SwiperSlide key={index} className=" lg:!h-12 ">
            <li className="flex h-full lg:h-[70px]" key={index}>
              <p className="p-3 w-full md:w-[25%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                {item.Source_Term}
              </p>
              
              <p className="p-3 bg-[#F0F5FF] w-full md:w-[75%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
              {item.English_Explanation}
              </p>
            </li>
            </SwiperSlide>
            
          ))}
          </>
        )}
          </>
         

          <div className="h-16 mt-8  flex gap-2 items-center justify-center">
            <div
              style={{ border: "none", outline: "none" }}
              className="absolute -bottom-[50px] w-[200px]  h-24  flex items-center justify-center gap-5 "
            >
              <img
                style={{ height: "60px", width: "60px" }}
                src={Nexticon}
                alt="Next"
                className=" swiper-button-next    left-[-30px] -translate-y-1/2 cursor-pointer"
              />
              <p className="mb-12 text-base font-semibold font-opensans text-[#424242]">
                {" "}
                Page {currentPage} of {totalPages}{" "}
              </p>
              <img
                style={{ height: "60px", width: "60px" }}
                src={Pre}
                alt="Previous"
                className=" h-[60px] w-[60px] swiper-button-prev   right-[-30px];  -translate-y-1/2 cursor-pointer"
              />
            </div>
          </div>
        </Swiper>
      ) : (
        <>
        {languagecode.target_language_code ? (
         <ul className="flex flex-col gap-2.5 overflow-y-auto mt-2">
       
         {analysisResult?.Glossary.map((item, index) => (
           <li key={index} className="flex h-full lg:h-[70px]">
           <p className="p-3 w-[33%] md:w-[25%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
             <span>{item.Term}</span>
           </p>
           <p className="p-3 w-[33%] md:w-[25%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans  bg-[#F0F5FF]   whitespace-wrap break-words">
             {item.Translation}
           </p>
           <p className="p-3 w-[34%] bg-[#E0EBFF] md:w-[50%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
             {item.Explanation}
           </p>
           {/* <button className="ml-3">
            <img src={cleanBtn} alt="closebutton" />
           </button> */}
         </li>
          ))}
         </ul>
        ) : (
          <ul className="flex flex-col gap-2.5 overflow-y-auto ">
          {analysisResult?.Glossary.map((item, index) => (
            <li className="flex h-full lg:h-[70px]" key={index}>
              <p className="p-3 w-full md:w-[25%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                {item.Source_Term}
              </p>
              
              <p className="p-3 bg-[#F0F5FF] w-full  md:w-[75%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                {item.English_Explanation}
              </p>
            </li>
          ))}
          </ul>
        )}
          
        </>
      )}
    </div>
  </div>
);
};

export default ContextualGlossaryOutput;
