import { useLocation } from "@reach/router";
import QualityIssuesOutput from "components/PreTranslation/QualityIssuesOutput";
import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import CopyIcon from "assets/copytools.jpg";



const SourceOutput = ({ result,error}) => {
  const [tooltipContent,setTooltipContent] = useState('copy')
  const location = useLocation();

  const url = location.href;
 const languagecode = result?.meta?.source_language_code

  const copyUrl = () => {
    navigator.clipboard.writeText(url);
    setTooltipContent('Link copied');
    
    
    setTimeout(() => {
      setTooltipContent('Copy');
    }, 2000);
  }
  
 return (
    <div className="w-full max-w-7xl mx-auto px-4 font-opensans py-12 lg:py-24">
      <div className="flex justify-between items-center pb-3 border-b border-[#C7C7C7]">
        <p className="text-lg text-gray26 font-bold font-opensans">
          Results
        </p>
        <button 
          className="flex items-center gap-2"
          data-tooltip-id="linkcopy-tooltip"
          data-tooltip-content={tooltipContent}
          onClick={copyUrl}
        >
          <img src={CopyIcon} alt="/copyicon"/>
        
          <span className="text-sm font-opensans text-[#5B93FF] font-semibold">
            Copy link
          </span>
        </button>
     
      </div>
      <Tooltip
          id="linkcopy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
      <QualityIssuesOutput
            header="Quality Issues Identified"
            output={ result?.result}
            languagecode={languagecode}
            ispretranslationterm ={true}
            ispretranslation={true}
          />
      {/* <div className="grid lg:grid-cols-2 gap-4">
        <div></div>
       <div></div>
      </div> */}
    </div>
  );
};

export default SourceOutput;
