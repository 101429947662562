import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBanner from "components/commontools/toolsBanner";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useState, useRef, useEffect } from "react";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import ResetButton from "assets/resetbutton.jpg";
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { QaToolFaqData } from "components/FAQ/config";
import { navigate } from "gatsby";
import useDebounce from "components/QualityTools/useDebounce";
import detectLanguage from "utils/detectLanguage";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [inputText, setInputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const refDiv = useRef(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const debouncedInputText = useDebounce(inputText, 3000);
    useEffect(() => {
      if (debouncedInputText?.trim()?.length >= 3) {
        detectLanguageForSource(debouncedInputText);
      }
    }, [debouncedInputText]);
    const detectLanguageForSource = async (text) => {
      const { languageCode} = await detectLanguage(text);
      if (languageCode) {
        setInputLang(languageCode);
      }
    };
    
  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError("");
    setShowOutput(false);

    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "translation_difficulty_checker",
        user_text: inputText,
        source_language_code: inputLang,
      });
      const newShareId = response?.meta?.share_id;

      setShowOutput(true);
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(`/tools/translation-difficulty-checker/result?share-id=${newShareId}`)
 
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const isButtonDisabled = !inputText || !inputLang;

  const handleReset = async () => {
    await handleClick();
  };
  const handleClearText = () => {
    setInputLang("");
    setInputText("");
    setWordCountInput(0);
  };
  
  return (
    <Layout>
      <SEO
        title="Translation Difficulty Checker"
        description="Evaluate your text's complexity with our translation difficulty checker to determine if you need machine translation, editing, or human translation.​"
        slug="/tools/translation-difficulty-checker"
      />
      <div className="bg-[#F5F5F5]">
        <div className="pt-5">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Translation Difficulty Checker"
            title="Identify the Best Translation Method for Your Text"
            description="Input text in any language, and the tool will analyze its complexity. You'll get a recommendation on whether to use raw machine translation, MTPE, or human translation. This makes it easier to choose the right translation method for your text, improving quality and efficiency."
          />
          <div className="lg:flex gap-3 mt-12">
            <div className="flex flex-col gap-3 w-full lg:w-[60%]">
              <div className="lg:flex items-center  rounded-lg shadow-lg ">
                <ToolInput
                lang={inputLang}
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setInputLang={setInputLang}
                  setWordCount={setWordCountInput}
                  iscleanBtn={true}
                  isDifficulty={true}
                  refDiv={refDiv}
                  handleClearText={handleClearText}
                  placeholder="Type your text here or"
                  tooltype = "pre"
                >
                  {" "}
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                    <p className=" text-[#6E6E6E] font-opensans font-bold">
                      Source text
                    </p>
                    {inputText && (
                      <img
                        src={CleanButton}
                        alt="clean-button"
                        onClick={handleClearText}
                        className="cursor-pointer  absolute right-[14px]"
                      />
                    )}
                  </div>
                </ToolInput>
              </div>
            </div>

            <ToolOutput
              handleClick={handleClick}
              isButtonDisabled={isButtonDisabled}
              ResetButton={ResetButton}
              loading={loading}
              handleReset={handleReset}
              showOutput={showOutput}
              error={error}
              ref={refDiv}
              isDifficulty={true}
              buttontext="Check Complexity"
              text='Your results will appear here. <br/> Please enter your texts and click "Check quality" to begin.'
            />
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>

          <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
            Input text in any language, and the tool will analyze its
            complexity. You'll get a recommendation on whether to use raw
            machine translation, MTPE, or human translation. This makes it
            easier to choose the right translation method for your text,
            improving quality and efficiency.
          </p>
        </div>
      </div>
      <div>
        <ToolFaq QaToolFaqData={QaToolFaqData} />
      </div>
    </Layout>
  );
};

export default Index;
