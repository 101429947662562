import React from "react";
import OutputHeader from "./OutputHeader";
import * as XLSX from 'xlsx';


const QualityIssuesOutput = ({ header, output ,ispretranslation,ispretranslationterm,languagecode}) => {
  const handleCopy = () => {
    const text = document.getElementById("quality-output-1").innerText;

    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy data: ", error);
      });
  };

  const handleCopytext = () => {
    const text = document.getElementById("quality-output-2").innerText;

    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy data: ", error);
      });
  };
  const downloadsxl = () => {
    const data = output?.for_review?.map((item) => ({
      Type: item.type,
      Description: item.items[0]?.description,
    }));

    const ws = XLSX.utils.json_to_sheet(data); 
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Quality Review");


    XLSX.writeFile(wb, "quality_issues_review.xlsx");
  };

 
  const downloadata = () => {
    const data = output?.dnt_terms?.map((item) => ({
      Key_Terms: item.term,
      Considerations: item?.explanation,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Special Terms");

   
    XLSX.writeFile(wb, "special_terms.xlsx");
  };

  return (
    <div className={`  ${!ispretranslation && !ispretranslationterm ? "grid lg:grid-cols-2 gap-4" : ""}`}>
      {ispretranslationterm && (
      <div className={`mt-5 shadow-box p-5  rounded-lg border border-lightBlue`}>
        <OutputHeader title="Potential Quality Concerns" downloadData={downloadsxl} tooltiptext="These identified issues, such as grammatical errors, inconsistencies, or sensitive content, could affect the quality of your translation if left unaddressed." copyText={handleCopy}  />
      
         <ul className="flex flex-col gap-2.5" id="quality-output-1">
          {output?.for_review &&
            output?.for_review.map((item, index) => (
 
                <li className="flex" key={index}>
                  <p className="p-3 w-full lg:max-w-[200px] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#FFBDBD] whitespace-wrap break-words">
                  {item.type}
                  </p>
                  <p className="p-3 bg-[#FFF0F0] w-full  border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                  {item.items[0]?.description}
                  </p>
                </li>
      
            ))}

        </ul>
  
      </div>
      )}
      {ispretranslation && (

      <div className={`mt-5 ${ispretranslationterm ? "mt-5" :""} shadow-box p-5 rounded-lg border border-lightBlue`}>
        <OutputHeader terms = {true} title="Important Terms for Review" downloadData={downloadata} tooltiptext="Highlights specific terms that may need special consideration during translation." copyText={handleCopytext}  />
        <p className="pb-4">These may require special attention or remain untranslated.</p>
        <ul className="flex flex-col gap-2.5" id="quality-output-2">
          <li className="flex">
            <p className="px-6 py-3 w-full lg:max-w-[200px] bg-[#BDD3FF] border-none outline-none text-[#575757] font-opensans text-center font-normal">
              Key Terms
            </p>
            <p className="px-6 py-3 w-full  bg-[#E0EBFF] border-none outline-none text-[#575757] font-opensans  text-center font-normal">
              Considerations
            </p>
          </li>
          <div className="mt-2 grid gap-2">
            {output?.dnt_terms &&
              output?.dnt_terms?.map((item, index) => (
                <li className="flex" key={index}>
                  <p className="p-3 w-full lg:max-w-[200px] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                    {item.term}
                  </p>
                  <p className="p-3 bg-[#F0F5FF] w-full  border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                    {item?.explanation}
                  </p>
                </li>
              ))}
          </div>

        </ul>
      </div>
      )}
    </div>
  );
};

export default QualityIssuesOutput;
