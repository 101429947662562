import Layout from "components/layout";
import SEO from "components/seo";
import React, { useEffect, useRef, useState } from "react";
import ToolFaq from "components/QualityTools/ToolFaq";
import { AnonymizationToolFaqData } from "components/FAQ/config";
import OutputBox from "components/AnonymizationTool/OutputBox";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import UploadIcon from "assets/uploadIcon.svg";
import CleanButton from "assets/cleanBtn.svg";
import { fileExtension } from "utils/fileextension";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import Loader from "components/loader";
import { navigate } from "gatsby";
import detectLanguage from "utils/detectLanguage";
import useDebounce from "components/QualityTools/useDebounce";
import { trySampleText } from "utils/sampletext-generator";
import { setegid } from "process";

const Index = () => {
  const [sourceText, setSourceText] = useState("");
  const [error, setError] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [shareId, setShareId] = useState("");
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [textLoader, setTextLoader] = useState(false);
  const [file, setFile] = useState(false);
  const textareaRef = useRef(null);
  const outputRef = useRef(null);

  const [inputLang, setInputLang] = useState("");
  const debouncedInputText = useDebounce(sourceText, 3000);
  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
    }
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 380);
    textarea.style.height = `${newHeight}px`;
  };

  const getAnonymizeText = async () => {
  
    try {
      setError("")
      setLoader(true);
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "anonymizeData",
        user_text: sourceText,
        source_language_code: inputLang,
      });
      const newShareId = response?.meta?.share_id;
    
      setShareId(newShareId);
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }

      navigate(`/tools/data-anonymization/result?share-id=${newShareId}`);
    } catch (error) {
      setError(error);
    } finally {
      setLoader(false);
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    setSourceText(text);

    adjustHeight();
  };
  useEffect(()=>{
    const words = sourceText.trim().split(/\s+/).filter((word) => word.length > 0).length;

  setWordCount(words);
  },[sourceText])
  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);

      setSourceText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };
  const title = "Data Anonymization";
  const description =
    "Secure your data with our anonymization tool. It redacts or encrypts identifiable information to ensure privacy compliance.";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        slug="/tools/data-anonymization"
      />
      <div>
        <div className="w-full py-7 ">
          <ToolsBreadcrumb />
        </div>

        <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] lg:pb-[60px] pt-12 pb-8 ">
          <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
            Data Anonymization Tool
          </h1>
          <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
            Safeguard Sensitive Information with Automated Redaction
          </h2>

          <div className="mt-12 flex lg:flex-row flex-col gap-4">
            <div className="shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans">
              <div className="flex justify-between">
                <span className="font-bold text-lg text-[#6E6E6E]">
                  {" "}
                  Source Text
                </span>
                {sourceText && (
                  <img
                    src={CleanButton}
                    className="cursor-pointer"
                    alt="clean-text-button"
                    onClick={() => {
                      setSourceText("");
                      setWordCount(0);
                    }}
                  />
                )}
              </div>

              <hr className="border border-[#B0B0B0] mt-2 mb-4" />

              {fileUploadLoading ? (
                <FileLoadingInput file={file} />
              ) : sizeLimitError && !fileUploadLoading ? (
                <ReUploadFile
                  file={file}
                  setFile={setFile}
                  setSizeLImitError={setSizeLImitError}
                  uploadFile={uploadFile}
                />
              ) : uploadError && !fileUploadLoading ? (
                <UnsupportedFile
                  file={file}
                  setSizeLImitError={setSizeLImitError}
                  setFile={setFile}
                  uploadFile={uploadFile}
                  setFileUploadError={setUploadError}
                />
              ) : (
                <div className="relative">
                  <textarea
                    dir={
                      inputLang === "he" || inputLang === "ar" ? "rtl" : "ltr"
                    }
                    ref={textareaRef}
                    value={sourceText}
                    onChange={handleTextChange}
                    className={`anonymize_tool  outline-none w-full resize-none text-base border-none ${
                      outputRef?.current?.offsetHeight
                        ? `min-h-[${outputRef?.current?.offsetHeight + 54}px]`
                        : "min-h-[225px]"
                    }  max-h-[400px]`}
                    placeholder={textLoader ? "" : "Type your text here or"}
                  />
                  {textLoader && (
                    <div className="absolute top-0 left-[14px] text-[#B4B4B4] italic">
                      Adding sample text...
                    </div>
                  )}
                  {!sourceText && !textLoader && (
                    <button
                      className="absolute top-0 left-[168px] underline italic text-[#5B93FF] cursor-pointer pointer-events-auto"
                      onClick={() =>
                        trySampleText(setSourceText, setTextLoader,"anonymization")
                      }
                    >
                      Try Sample Text
                    </button>
                  )}
                </div>
              )}
              <div className="mt-5">
                <div className="flex md:flex-row items-end flex-col gap-2.5 justify-between">
                  <div className="flex-1">
                    {sourceText ? (
                      <p className="text-base text-[#9C9C9C] ">
                        {wordCount} words
                      </p>
                    ) : (
                      <>
                        <label
                          htmlFor="uploadFile"
                          className="flex gap-2 cursor-pointer"
                        >
                          <img src={UploadIcon} alt="upload_icon" />
                          <span className="text-[#858585] font-sm font-opensans font-semibold">
                            Upload File
                          </span>
                        </label>
                        <input
                          type="file"
                          className="hidden"
                          id="uploadFile"
                          onChange={uploadFile}
                        />
                      </>
                    )}
                  </div>
                  <button
                    disabled={!sourceText || !inputLang}
                    onClick={getAnonymizeText}
                    className={`${
                      !sourceText || !inputLang
                        ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed "
                        : "bg-[#5B93FF] text-white"
                    }  outline-none font-opensans  font-semibold text-base flex items-center justify-center px-6 py-3 rounded-lg gap-3 border`}
                  >
                    {loader ? <Loader /> : " Anonymize my data"}
                  </button>
                </div>

                <p className="text-[12px] text-[#6E6E6E] bg-[#F0F5FF] p-2 rounded-lg mt-3 font-opensans">
                  This AI tool may not catch all sensitive information. Check
                  the output before sharing.
                </p>
              </div>
            </div>
            <OutputBox error={error}/>
          </div>
          <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans">
            Upload your dataset, and the tool will automatically remove or
            encrypt sensitive information to protect privacy and comply with
            regulations. This ensures your data remains secure and anonymous
            while maintaining its usability for analysis or processing.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={AnonymizationToolFaqData} />
    </Layout>
  );
};
export default Index;
