import React, { useState, useEffect, useRef } from "react";
import { navigate } from "gatsby";
import useDebounce from "./useDebounce";
import axios from "axios";
import ResetButton from "assets/resetbutton.jpg";
import Toolinput from "components/commontools/InsertTool";
import ToolsBanner from "components/commontools/toolsBanner";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import CleanButton from "assets/cleanBtn.svg";
import { Languages } from "./LanguagesData";
import detectLanguage from "utils/detectLanguage";

const QualityAnalysis = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const refDiv = useRef(null);
  const debouncedInputText = useDebounce(inputText, 3000);
  const debouncedOutputText = useDebounce(outputText, 3000);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
  useEffect(() => {
    if (refDiv.current) {
      setDivHeight(refDiv.current.base.offsetHeight);
    }
  }, []);

  

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);

  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectLanguageForTarget(debouncedOutputText);
    }
  }, [debouncedOutputText]);

  const detectLanguageForSource = async (text) => {
    const { languageCode, languageName } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
      setTranslateFromInput(languageName);
    }
  };

  const detectLanguageForTarget = async (text) => {
    const { languageCode, languageName } = await detectLanguage(text);
    if (languageCode) {
      setOutputLang(languageCode);
      setTranslateToInput(languageName);
    }
  };
  // useEffect(() => {
  //   if (debouncedInputText?.trim()?.length >= 3) {
  //     detectSourceLanguage(debouncedInputText);
  //   }
  // }, [debouncedInputText]);
  // useEffect(() => {
  //   if (debouncedOutputText?.trim()?.length >= 3) {
  //     detectTargetLanguage(debouncedOutputText);
  //   }
  // }, [debouncedOutputText]);

  // const detectSourceLanguage = async (text) => {
  //   try {
  //     const response = await axios.post(
  //       "https://api.machinetranslation.com/v1/detect/language",
  //       {
  //         text: text.slice(0, 50),
  //       }
  //     );
  //     const language = response.data?.data?.language_probability?.code;
  //     const languageName = Languages.find((lang) => lang?.code === language);

  //     if (languageName?.name) {
  //       setInputLang(language);
  //     }

  //     const languageCode = response.data?.data?.language_probability?.name;
  //     setTranslateFromInput(languageCode);
  //   } catch (error) {
  //     console.error("Error detecting language:", error);
  //   }
  // };
  // const detectTargetLanguage = async (text) => {
  //   try {
  //     const response = await axios.post(
  //       "https://api.machinetranslation.com/v1/detect/language",
  //       {
  //         text: text.slice(0, 50),
  //       }
  //     );
  //     const languageTo = response.data?.data?.language_probability?.code;
  //     const languageToName = Languages.find(
  //       (lang) => lang?.code === languageTo
  //     );
  //     if (languageToName?.name) {
  //       setOutputLang(languageTo);
  //     }
  //     const languageCode = response.data?.data?.language_probability?.name;

  //     setTranslateToInput(languageCode);
  //   } catch (error) {
  //     console.error("Error detecting language:", error);
  //   }
  // };

  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError("");
    setShowOutput(false);

    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "translationQuality",
        source_text: inputText,
        translation: outputText,
        source_language_code: inputLang,
        target_language_code: outputLang,
      });
      const newShareId = response?.meta?.share_id;
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(
        `/tools/translation-quality-assurance/result?share-id=${newShareId}`
      );
      setShowOutput(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText;

  // const handleReset = async () => {
  //   await handleClick();
  // };
  const handleSourceClearText = () => {
    setInputLang("");
    setInputText("");
    setWordCountInput(0);
  };
  const handleTargetClearText = () => {
    setOutputLang("");
    setOutputText("");
    setWordCountOutput(0);
  };

  return (
    <div className="bg-[#F5F5F5]">
      <div className="py-7">
        <ToolsBreadcrumb />
      </div>
      <div className="max-w-7xl mx-auto px-4 py-12 lg:py-[60px]">
        <ToolsBanner
          text="TRANSLATION QUALITY ASSURANCE TOOL"
          title="Identify and Resolve Quality Issues Quickly"
          description="  Enter your source text and its translation, and get detailed
              feedback on accuracy, fluency, and overall translation quality
              instantly."
        />
        <div className="lg:flex gap-3 mt-12">
          <div className="flex flex-col gap-3 w-full lg:w-[70%]">
            <div className="lg:flex items-center  rounded-lg shadow-lg ">
              <Toolinput
                lang={inputLang}
                text={inputText}
                wordCount={wordCountInput}
                setText={setInputText}
                setOutputText={setOutputText}
                setInputLang={setInputLang}
                setOutputLang={setOutputLang}
                setTranslateToInput={setTranslateToInput}
                setTranslateFromInput={setTranslateFromInput}
                setAnalysisResult={setAnalysisResult}
                setShowOutput={setShowOutput}
                setWordCount={setWordCountInput}
                iscleanBtn={true}
                refDiv={refDiv}
                result={analysisResult?.result}
                divHeight={divHeight}
                placeholder="Enter your text here"
       
              >
                <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                  <p className=" text-[#6E6E6E] font-opensans font-bold">
                    Source text
                  </p>
                  {inputText && (
                    <img
                      src={CleanButton}
                      alt="clean-button"
                      onClick={handleSourceClearText}
                      className="cursor-pointer  absolute right-[14px]"
                    />
                  )}
                </div>
              </Toolinput>

              <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

              <Toolinput
                lang={outputLang}
                text={outputText}
                wordCount={wordCountOutput}
                setText={setOutputText}
                setWordCount={setWordCountOutput}
                refDiv={refDiv}
                result={analysisResult?.result}
                divHeight={divHeight}
                isTranslation={true}
                placeholder="Enter your text here"
          
              >
                <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                  <p className=" text-[#6E6E6E] font-opensans font-bold">
                    Translation
                  </p>
                  {outputText && (
                    <img
                      src={CleanButton}
                      alt="clean-button"
                      onClick={handleTargetClearText}
                      className="cursor-pointer  absolute right-[14px]"
                    />
                  )}
                </div>
              </Toolinput>
            </div>
          </div>

          <ToolOutput
            handleClick={handleClick}
            isButtonDisabled={isButtonDisabled}
            ResetButton={ResetButton}
            loading={loading}
            // handleReset={handleReset}
            showOutput={showOutput}
            error={error}
            ref={refDiv}
            buttontext="Check quality"
            isQuality={true}
            text="  Your results will appear here. <br /> Please enter your texts and click 'Check quality' to begin."
          />
        </div>
        <p className="w-full text-center  text-base mt-12 pb-4 text-[#9C9C9C]">
          Assisted by AI tools. Inaccuracies may occur.
        </p>

        <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
          Enter your source text and its translation, and get detailed feedback
          on accuracy, fluency, and overall translation quality instantly.
        </p>
      </div>
    </div>
  );
};

export default QualityAnalysis;
