import React, { useEffect, useState } from "react";
import OutputHeader from "./OutputHeader";
import * as XLSX from 'xlsx';

const TextOutput = ({ header, textoutput,languagecode}) => {
    const [wordCount,setWordCount] = useState(0)

    useEffect(() => {
        const summarizedText = textoutput?.summarized_text || "";
        const wordLength = summarizedText
          .trim()
          .split(/\s+/)
          .filter((word) => word.length > 0).length;
        setWordCount(wordLength);
      }, [textoutput?.summarized_text]);
    

  const formatText = (text) => {
    if (text) {
    
      return text.split('.').map((sentence, index) => sentence.trim()).filter(Boolean);
    }
    return [];
  };


  const handleCopy = () => {
    const text = document.getElementById("quality-output").innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy data: ", error);
      });
  };
  const downloadsxl = () => {
    const summarizedText = textoutput?.summarized_text ? formatText(textoutput.summarized_text) : [];

    if (summarizedText.length === 0) {
      alert("No data to download.");
      return;
    }

    const data = summarizedText.map((item) => ({
      "Summarized Text": item,
    }));

    const ws = XLSX.utils.json_to_sheet(data);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Summarized Text");

    XLSX.writeFile(wb, "summarized_text.xlsx");
  };


  const summarizedText = textoutput?.summarized_text ? formatText(textoutput.summarized_text) : [];

  return (
    <div className="my-5">
      <div className={`shadow-box p-5 rounded-lg border border-lightBlue`}>
        <OutputHeader
          title="Summarized text"
          tooltiptext="These identified issues, such as grammatical errors, inconsistencies, or sensitive content, could affect the quality of your translation if left unaddressed."
          copyText={handleCopy}
          downloadData={downloadsxl} 
        />

        <div className="flex flex-col gap-2.5" id="quality-output">
        {summarizedText.length > 0 ? (
            <ul dir={
              languagecode.source_language_code === "fa" ||
              languagecode.source_language_code === "he" ||
              languagecode.source_language_code === "ar"
                ? "rtl"
                : "ltr"
            } className="list-disc px-5"> 
              {summarizedText.map((item, index) => (
                <li  key={index}     className="text-lg text-black">
              {item}
                </li>
              ))}
            </ul>
          ) : (
            <p>No summarized text available.</p>
          )}
        </div>
      <div className="mt-4 px-4 py-4 text-[#9C9C9C] text-sm font-semibold">
       {wordCount} words
      </div>

      </div>
    </div>
  );
};

export default TextOutput;
