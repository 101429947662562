import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBanner from "components/commontools/toolsBanner";
import Layout from "components/layout";

import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useEffect, useState, useRef } from "react";
import { navigate } from "gatsby";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { ContextualToolFaqData } from "components/FAQ/config";
import useDebounce from "components/QualityTools/useDebounce";
import { Languages } from "components/QualityTools/LanguagesData";
import axios from "axios";
import SourceLanguage from "components/ToolsLanguageDropdown/SourceLangauge";
import TargetLanguage from "components/ToolsLanguageDropdown/TargetLanguage";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inputText, setInputText] = useState("");
  const [wordCountInput, setWordCountInput] = useState(0);
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const containerRef = useRef();

  const debouncedInputText = useDebounce(inputText);
  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectSourceLanguage(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectSourceLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const language = response.data?.data?.language_probability?.code;
      const languageName = Languages.find((lang) => lang?.code === language);

      if (languageName?.name) {
        setInputLang(language);
      }
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };

  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError("");

    const payload = {
      tool_name: "contextual_glossary_generator",
      user_text: inputText,
      source_language_code: inputLang,
    };
    if (outputLang) {
      payload.target_language_code = outputLang;
    }

    try {
      const response = await http().post(endpoints.forms.aiTools, payload);
      const newShareId = response?.meta?.share_id;
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(
        `/tools/contextual-glossary-generator/result?share-id=${newShareId}`
      );
    } catch (error) {
      console.error("Error during quality analysis:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const isButtonDisabled = !inputText || !inputLang;

  const handleClearText = () => {
    setInputText("");
    setWordCountInput(0);
    setError("");
  };

  return (
    <Layout>
      <SEO
        title="Contextual Glossary Generator​"
        description="Create a tailored glossary with the Contextual Glossary Generator. Analyze any text to find key terms and definitions for an industry-specific reference guide."
        slug="/tools/contextual-glossary-generator"
      />

      <div className="bg-[#F5F5F5]">
        <div className="w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Contextual Glossary Generator"
            title="Easily Extract Key Terms and Definitions from Any Text"
            description="The Contextual Glossary Generator quickly identifies and defines key terms from your text, making it easy to build a specialized glossary for any document. Just upload content in any language, and let the tool do the rest."
          />

          <div className="lg:flex gap-3 h-full mt-12">
            <div
              ref={containerRef}
              className={`flex flex-col gap-3 w-full lg:w-[65%] bg-white h-full rounded-lg shadow-lg`}
            >
              <div className="flex md:flex-row flex-col px-3 mt-3 gap-3">
                <SourceLanguage
                  inputLang={inputLang}
                  setInputLang={setInputLang}
                  containerRef={containerRef}
                />
                <TargetLanguage
                  outputLang={outputLang}
                  setOutputLang={setOutputLang}
                  containerRef={containerRef}
                />
              </div>
              <div className="lg:flex items-center">
                <ToolInput
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setWordCount={setWordCountInput}
                  handleClearText={handleClearText}
                  isContextual={true}
                  lang={inputLang}
                  placeholder="Type your text here or"
                  tooltype="pre"
                >
                  {inputText && (
                    <img
                      src={CleanButton}
                      alt="clean-button"
                      onClick={handleClearText}
                      className={`cursor-pointer ${
                        inputLang === "fa" ||
                        inputLang === "he" ||
                        inputLang === "ar"
                          ? "left-[32px]"
                          : "right-[32px]"
                      }  absolute  top-8`}
                    />
                  )}
                </ToolInput>
              </div>
            </div>

            <ToolOutput
              handleClick={handleClick}
              isButtonDisabled={isButtonDisabled}
              loading={loading}
              error={error}
              isContextual={true}
              buttontext="Generate Glossary"
              text="Your results will appear here. <br /> Please enter your texts and click 'Generate Glossary' to begin."
            />
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>

          <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
            The Contextual Glossary Generator quickly identifies and defines key
            terms from your text, making it easy to build a specialized glossary
            for any document. Just upload content in any language, and let the
            tool do the rest.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={ContextualToolFaqData} />
    </Layout>
  );
};

export default Index;
