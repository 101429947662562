import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import Serachicon from "assets/serachicon.jpg";

function TranslateToList({
  data,
  TranslateToRef,
  TransToOpen,
  transtalteToInput,
  setTransToOpen,
  TranslateTostyles,
  ToSearchInput,
  setToSearchInput,
  handleTranslateToInput,
  sameLanguageError,
  divHeight
}) {
  const location = useLocation();
  const [hoveredItem, setHoveredItem] = useState(null);
  

  const handleOptionHover = (source_language) => {
    setHoveredItem(source_language);
  };
  // if (transtalteToInput == "Select target language") {
  // }
  const handleOpClick = () => {
    setTransToOpen(false);
    // setLanguageSelection(false);
  };
  // useEffect(() => {
  //   const elem = document.getElementById("input-textarea");
  //   const height = getComputedStyle(elem).height;
  //   setDropdownHeight(height);
  // }, []);
  return (
    <>
      <label className="flex flex-col w-full font-opensans mb-5 cursor-pointer items-center ">
        <div className="w-full ">
          <div
            className="  w-full font-arial group relative"
            ref={TranslateToRef}
          >
            <div className="hidden lg:block">
              <div
                className={` h-[50px] px-4 rounded-xl bg-transparent font-opensans border w-full border-solid border-quotetext-100 text-[#777777] text-base font-semibold mb-1 flex items-center justify-between`}
                onClick={() => setTransToOpen(!TransToOpen)}
              >
                <p>
                  {transtalteToInput ? (
                    <span>{transtalteToInput}</span>
                  ) : (
                    "Target language (optional)"
                  )}
                </p>
                <span
                  className={`text-quotetext-200 arrow ${
                    TransToOpen ? "rotate-[316deg]" : "rotate-[135deg]"
                  }  transition-all duration-200 ease-linear  `}
                ></span>
              </div>

              <div
                style={TranslateTostyles}
                className="bg-white w-full border border-[#B4B4B4] p-2 rounded-lg absolute z-50"
              >
                <div className={`bg-white w-full`}>
                  <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <img
                        src={Serachicon}
                        alt="search_icon"
                        className="z-50"
                      />
                    </div>
                    <input
                      placeholder="Search language"
                      className={`h-[50px] px-4 ps-10  z-51 bg-white w-full rounded-lg outline-none border-quotetext-100  border border-solid  text-[#777777] text-base flex items-center justify-between`}
                      value={ToSearchInput}
                      onChange={(e) => setToSearchInput(e.target.value)}
                      required
                    />
                  </div>
                  <div
                    className="overflow-y-scroll"
                    style={{
                      maxHeight: divHeight ? `${divHeight}px` : `230px`,
                    }}
                  >
                    <ul
                      className="rounded-bl-lg rounded-br-lg h-[230px ]  visible top-[100px] w-full   bg-white "
                      style={TranslateTostyles}
                    >
                      {data
                        ? data?.data
                            ?.filter(({ name }) => {
                              if (ToSearchInput === "") {
                                return name;
                              } else if (
                                name
                                  .toLowerCase()
                                  .includes(ToSearchInput.toLowerCase())
                              ) {
                                return name;
                              }
                            })
                            ?.map(({ name, code, priority }) => (
                              <li
                                className={`h-[50px] px-4 w-full text-quotetext-200 flex items-center justify-between ${
                                  priority === 0
                                    ? "border-b border-quotetext-100"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleTranslateToInput(name, code)
                                }
                              >
                                {name}
                              </li>
                            ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="block lg:hidden">
              <div
                className={`h-[50px] px-4 bg-white font-opensans rounded-xl border w-full border-solid border-quotetext-100 text-base font-semibold text-[#777777] mb-1 flex items-center justify-between`}
                onClick={() => setTransToOpen(!TransToOpen)}
              >
                <p>
                  {transtalteToInput ? (
                    <span>Detect - {transtalteToInput}</span>
                  ) : (
                    "Detect language"
                  )}
                </p>
                <span
                  className={`text-quotetext-200 arrow ${
                    TransToOpen ? "rotate-[316deg]" : "rotate-[135deg]"
                  }  transition-all duration-200 ease-linear  `}
                ></span>
              </div>
              <div
                className={` bg-white transform top-0  right-0 w-full fixed h-[100vh] rounded-t-lg  lg:hidden overflow-auto ease-in-out transition-all duration-500 z-[99]
                  ${TransToOpen ? "-translate-y-0" : "translate-y-full"}`}
              >
                {/* <div className="bg-white rounded-t-lg text-black mt-2 flex justify-between px-[20px] w-full py-2 font-opensans text-[17px] font-semibold leading-6">
                  <p>Translate From</p>
                  <button
                    className="text-[#007AFF] text-[17px] font-opensans leading-[22px]"
                    onClick={() => handleOpClick()}
                    type="button"
                  >
                    Close
                  </button>
                </div> */}
                <div className="relative px-[20px] mt-[14px] mb-[15px] outline-none border-none">
                  <div className="absolute inset-y-0 start-4 flex items-center ps-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M6.3833 13.3767C7.76123 13.3767 9.04785 12.9285 10.0938 12.1814L14.0283 16.116C14.2109 16.2986 14.4517 16.3899 14.7007 16.3899C15.2402 16.3899 15.6304 15.9749 15.6304 15.4436C15.6304 15.1946 15.5474 14.9622 15.3647 14.7795L11.4551 10.8616C12.2769 9.78247 12.7666 8.44604 12.7666 6.99341C12.7666 3.48218 9.89453 0.610107 6.3833 0.610107C2.86377 0.610107 0 3.48218 0 6.99341C0 10.5046 2.86377 13.3767 6.3833 13.3767ZM6.3833 11.9988C3.63574 11.9988 1.37793 9.73267 1.37793 6.99341C1.37793 4.25415 3.63574 1.98804 6.3833 1.98804C9.12256 1.98804 11.3887 4.25415 11.3887 6.99341C11.3887 9.73267 9.12256 11.9988 6.3833 11.9988Z"
                        fill="#8E8E93"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    style={TranslateTostyles}
                    className={` h-[50px] px-4 ps-10  z-51 bg-[#FFFFFF] w-full rounded-lg outline-none focus:border-[#3F1573] border border-solid  text-[#777777] text-base flex items-center justify-between `}
                    placeholder="Search..."
                    value={ToSearchInput}
                    onChange={(e) => setToSearchInput(e.target.value)}
                    required
                  />
                </div>

                <ul
                  className="px-[20px] h-[calc(100vh-0px)] overflow-auto "
                  style={TranslateTostyles}
                >
                  {data
                    ? data?.data
                        ?.filter(({ name }) => {
                          if (ToSearchInput === "") {
                            return name;
                          } else if (
                            name
                              .toLowerCase()
                              .includes(ToSearchInput.toLowerCase())
                          ) {
                            return name;
                          }
                        })
                        ?.map(({ name, code, priority }) => (
                          <>
                            <li
                              onMouseEnter={() => handleOptionHover(name)}
                              onMouseLeave={() => setHoveredItem(null)}
                              className={`h-[50px] px-4 w-full hover:bg-[#00173A] rounded-lg hover:text-white flex items-center justify-between ${
                                transtalteToInput === name
                                  ? "bg-[#00173A] text-[#ffffff] font-semibold "
                                  : "text-quotetext-200"
                              }`}
                              onClick={() => handleTranslateToInput(name, code)}
                              value={name}
                            >
                              <p className="w-full flex justify-between cursor-pointer ">
                                {name}
                                {hoveredItem === transtalteToInput ||
                                transtalteToInput === name ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                  >
                                    <path
                                      d="M7.5 15L12.8033 20.3033L23.4087 9.69666"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                ) : null}
                              </p>
                            </li>
                          </>
                        ))
                    : null}
                </ul>
              </div>
            </div>
          </div>

          {sameLanguageError && (
            <span className="text-red">
              Please select a different language.
            </span>
          )}
        </div>
      </label>
    </>
  );
}

export default TranslateToList;
