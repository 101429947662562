import React, { useState, useEffect, useRef } from "react";
import ResetButton from "assets/resetbutton.jpg";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBanner from "components/commontools/toolsBanner";
import ToolInput from "components/commontools/InsertTool";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import GuidelineOutput from "components/commontools/GuidelineOutput";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { GuidanceToolFaqData } from "components/FAQ/config";
import Loader from "components/loader";
const Guidelineresult = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false)
  const [showOutput, setShowOutput] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [guidelineText, setGuidelineText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
   const [guidelineLang,setGuidelineLang] = useState("")
  const refDiv = useRef(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
  const [guidelineCount, setGuidelineCount] = useState(0);
  const [isLgScreen, setIsLgScreen] = useState(false);
  const [shareId, setShareId] = useState("");
  const outputRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsLgScreen(window.innerWidth > 1024);
      const handleResize = () => {
        setIsLgScreen(window.innerWidth > 1024);
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (refDiv.current) {
      const height = refDiv.current.offsetHeight;
      setDivHeight(height);
    }
  }, [inputText, outputText, guidelineText, analysisResult]);

  const handleClick = async () => {
    if (!isButtonDisabled) return;

    setLoading(true);
    setError(false);
    setShowOutput(false);
    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "guideline_adherence_checker",
        source_text: inputText,
        translation: outputText,
        user_text: guidelineText,
        source_language_code: inputLang,
        target_language_code: outputLang,
        guideline_language_code:guidelineLang
      });
      const newShareId = response?.meta?.share_id;
      setShareId(newShareId);
      setShowOutput(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = shareId ? shareId : UpdatreshareId;

  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {
 
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        setAnalysisResult(response);
        setShowOutput(true);
        const {
          source_text: sourceText,
          translation_text: translatedText,
          user_text: userTxt,
          source_language_code:sourceCode,
        target_language_code:targetCode,
        guideline_language_code:guidelineCode
        } = response.meta;
        setInputText(sourceText);
        setOutputText(translatedText);
        setGuidelineText(userTxt);
        setInputLang(sourceCode)
        setOutputLang(targetCode)
        setGuidelineLang(guidelineCode)
     


      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };
    fatchAnalysis();
  }, [displayShareId]);

  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText || !guidelineText;

  const handleReset = async () => {

    await handleClick();
  };

  useEffect(() => {
    if (showOutput && outputRef.current) {

      const outputPosition = outputRef.current.getBoundingClientRect().top + window.scrollY;
      
     
      window.scrollTo({
        top: outputPosition - 230, 
        behavior: 'smooth',
      });
    }
  }, [showOutput, analysisResult]);
  
  if (loading)
    return (

        <div className="grid place-content-center h-[calc(100vh-150px)]">
          <Loader />
        </div>

    );
  return (
    <Layout>
      <SEO
        title="Translation Guideline Adherence Checker"
        description="Check if your translation guidelines were followed by your translator with our Translation Guideline Adherence Checker."
        slug="/tools/guideline-adherence-checker"
      />
      <div className="bg-[#F5F5F5] ">
        <div className=" w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Translation Guidelines Adherence Checker"
            title="Automated Guideline Checks for Efficient Quality Control"
            description="Enter your source text, translation, and guidelines. The tool checks for any deviations and provides a report with a compliance score and improvement suggestions. Meet your client's expectations and make revisions easier."
          />
          <div className="lg:flex gap-3 mt-12">
            <div className="flex flex-col gap-3 w-full lg:w-[70%]">
              <div className="lg:flex items-center  rounded-lg shadow-lg ">
                <ToolInput
                  lang={inputLang}
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setOutputText={setOutputText}
                  setInputLang={setInputLang}
                  setOutputLang={setOutputLang}
                  setTranslateToInput={setTranslateToInput}
                  setTranslateFromInput={setTranslateFromInput}
                  setAnalysisResult={setAnalysisResult}
                  setShowOutput={setShowOutput}
                  setWordCount={setWordCountInput}
                  iscleanBtn={true}
                  refDiv={refDiv}
                  result={analysisResult}
                  // divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
        
                >
                  {" "}
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                          Source text
                        </p> 
                        {inputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={()=> navigate(`/tools/guideline-adherence-checker/`)}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                </ToolInput>

                <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                <ToolInput
                  lang={outputLang}
                  text={outputText}
                  wordCount={guidelineCount}
                  setText={setOutputText}
                  setWordCount={setGuidelineCount}
                  refDiv={refDiv}
                  result={analysisResult}
                  // divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  isTranslation ={true}
                 
                >
                 
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                        Translated text
                        </p> 
                        {outputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={()=> navigate(`/tools/guideline-adherence-checker/`)}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                </ToolInput>
              </div>
            </div>
            <ToolInput
              lang={guidelineLang}
              text={guidelineText}
              wordCount={wordCountOutput}
              setText={setGuidelineText}
              setWordCount={setWordCountOutput}
              refDiv={refDiv}
              result={analysisResult}
              divHeight={divHeight}
              placeholder="Enter your text here"
              isGuidance={true}
   
              buttontext="Evaluate Text"
              handleClick={handleClick}
              isButtonDisabled={isButtonDisabled}
              ResetButton={ResetButton}
              loading={loading}
              handleReset={handleReset}
              setDivHeight={setDivHeight}
              isTranslation ={false}
            >
         
              <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                        Guidelines
                        </p> 
                        {guidelineText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={()=> navigate(`/tools/guideline-adherence-checker/`)}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
            </ToolInput>
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <div ref={outputRef}>
            <GuidelineOutput
              // divHeight={divHeight}
              analysisResult={analysisResult}
              showOutput={showOutput}
              isLgScreen={isLgScreen}
              loading={loading}
              error={error}
              refDiv={refDiv}
            />
          </div>
          <p className=" mt-12 text-lg text-center font-opensans leading-[26px]">
            Enter your source text, translation, and guidelines. The tool checks
            for any deviations and provides a report with a compliance score and
            improvement suggestions. Meet your client's expectations and make
            revisions easier.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={GuidanceToolFaqData} />
    </Layout>
  );
};

export default Guidelineresult;
