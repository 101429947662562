import { endpoints } from "./endpoints";
import http from "./http";

export const trySampleText = (setText, setTextLoader,anonymization) => {
 
  setTextLoader(true);

  const fetchSampleText = async () => {
    try {
    const res = await http().get(
        `${endpoints.forms.trysampletext}?tool_name=${anonymization ? "tryToolTextAnonymizeTool" : "tryToolText"}`
      );

     
      const data = res.result.text;
  
      setText(data || 'No sample text available');
    } catch (error) {
      console.error("Error fetching sample text:", error);
      setText('Failed to load sample text');
    } finally {
      setTextLoader(false);
    }
  };

  fetchSampleText();
};
