import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "@reach/router";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { navigate } from "gatsby";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import Loader from "components/loader";
import SourceOutput from "components/QualityTools/SourceOutput";

const Result = () => {
  const [text, setText] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [seeMoreText, setSeeMoreText] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const [inputLang, setInputLang] = useState("");
  const location = useLocation();
  const textRef = useRef(null);
  const params = new URLSearchParams(location?.search);
  const shareId = params.get("share-id");

  useEffect(() => {
    const fetchAnalysis = async () => {

      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${shareId}`
        );
      
        setResult(response);
        setText(response.meta.user_text)
        setInputLang(response)
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setPageLoader(false);
      }
    };
    fetchAnalysis();
  }, []);
  useEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current) {
        const lineHeight = parseFloat(
          getComputedStyle(textRef.current).lineHeight
        );
        const maxLinesHeight = lineHeight * 3;

        if (textRef.current.scrollHeight > maxLinesHeight) {
          setIsTextOverflowing(true);
        } else {
          setIsTextOverflowing(false);
        }
      }
    };
    checkTextOverflow();
  }, [text,shareId]);
  
  if (pageLoader)
  
    return (
  
        <div className="grid place-content-center h-[calc(100vh-150px)]">
          <Loader />
        </div>

    );

  return (
    <Layout>
      <SEO
        title="Source Text Checker"
        description="Check your source text for mistake before translation with our AI grammer checker.Get a report on grammer errors,typos,inconsistent terms,and more."
        slug="/tools/source-text-checker"
      />
      <div className="bg-[#F5F5F5]">
        <div className="pt-7">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-12 lg:py-24">
          <p className="text-[#424242] font-bold text-lg font-opensans">
            Source text
          </p>
          <hr className="border-[#C7C7C7] border mt-3 mb-4" />
          <div className="shadow-box relative p-3 rounded-lg">
            <div dir={
                    inputLang.meta?.source_language_code === "fa" ||
                    inputLang.meta?.source_language_code === "he" ||
                    inputLang.meta?.source_language_code === "ar"
                      ? "rtl"
                      : "ltr"
                  } className="flex gap-3">
              <p
                   
               ref={textRef}
                id="output"
                className={`tool pr-5 text-lg font-sans text-[#575757] ${
                  !seeMoreText && " line-clamp-3"
                }`}
                dangerouslySetInnerHTML={{__html: text.replace(/\n/g, "<br />")}}
              />
              
            
              <svg
                onClick={() => navigate("/tools/source-text-checker")}
                className={`${inputLang.meta?.source_language_code === "fa" ||
                  inputLang.meta?.source_language_code === "he" ||
                  inputLang.meta?.source_language_code === "ar" ? "left-3" : "right-3"} absolute top-4  cursor-pointer`}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M11.7472 10.5355C11.9081 10.6964 11.9986 10.9148 11.9986 11.1424C11.9986 11.37 11.9081 11.5884 11.7472 11.7493C11.5862 11.9103 11.3679 12.0007 11.1403 12.0007C10.9126 12.0007 10.6943 11.9103 10.5333 11.7493L6 7.21455L1.46523 11.7479C1.30427 11.9089 1.08595 11.9993 0.858315 11.9993C0.630676 11.9993 0.41236 11.9089 0.251395 11.7479C0.0904294 11.5869 2.39857e-09 11.3686 0 11.141C-2.39857e-09 10.9133 0.0904294 10.695 0.251395 10.5341L4.78616 6.00071L0.252822 1.46595C0.0918573 1.30498 0.00142808 1.08667 0.00142808 0.859029C0.00142808 0.63139 0.0918573 0.413074 0.252822 0.252109C0.413788 0.0911433 0.632104 0.000713906 0.859743 0.000713904C1.08738 0.000713902 1.3057 0.0911433 1.46666 0.252109L6 4.78687L10.5348 0.251394C10.6957 0.0904292 10.914 -3.79247e-09 11.1417 0C11.3693 3.79247e-09 11.5876 0.0904292 11.7486 0.251394C11.9096 0.41236 12 0.630675 12 0.858315C12 1.08595 11.9096 1.30427 11.7486 1.46524L7.21384 6.00071L11.7472 10.5355Z"
                  fill="#69AAE8"
                />
              </svg>
            </div>
            {isTextOverflowing && (
              <div className="mt-4">
                <button
                  onClick={() => setSeeMoreText(!seeMoreText)}
                  className="mx-auto max-w-max flex items-center text-[#5B93FF] font-semibold font-opensans"
                >
                  {!seeMoreText ? "See more" : "See less"}
                  <svg
                    className={`ml-2.5 ${
                      seeMoreText && "rotate-180"
                    } ease-in-out duration-150`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                  >
                    <path
                      d="M8.5 1.0625L4.74593 4.34765C4.68069 4.40471 4.59222 4.43679 4.5 4.43679C4.40778 4.43679 4.31931 4.40471 4.25407 4.34765L0.5 1.0625"
                      stroke="#5B93FF"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.5 4.56323L4.74593 7.84839C4.68069 7.90545 4.59222 7.93753 4.5 7.93753C4.40778 7.93753 4.31931 7.90545 4.25407 7.84839L0.5 4.56323"
                      stroke="#5B93FF"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
          <SourceOutput result={result}  />
        </div>
      </div>
    </Layout>
  );
};

export default Result;
