import Layout from "components/layout";
import SEO from "components/seo";
import React, { useEffect } from "react";
import { useRef, useState } from "react";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { fileExtension } from "utils/fileextension";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import UploadIcon from "assets/uploadIcon.svg";
import CleanButton from "assets/cleanBtn.svg";
import Loader from "components/loader";
import { navigate } from "gatsby";
import ToolFaq from "components/QualityTools/ToolFaq";
import { StyleGuideToolFaqData } from "components/FAQ/config";
import useDebounce from "components/QualityTools/useDebounce";
import axios from "axios";
import { Languages } from "components/QualityTools/LanguagesData";
import { direction } from "utils/direction";
import detectLanguage from "utils/detectLanguage";
import { trySampleText } from "utils/sampletext-generator";

const Index = () => {
  const [sourceText, setSourceText] = useState("");
  const [error, setError] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [file, setFile] = useState(false);
  const textareaRef = useRef(null);
  const [inputLang, setInputLang] = useState("");
  const [textLoader, setTextLoader] = useState(false);

  const debouncedInputText = useDebounce(sourceText);
  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);

  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
    }
  };
  const handleTextChange = (e) => {
    const text = e.target.value;

    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);

    // if (words.length > 1000) {
    //   e.target.value = words.slice(0, 1000).join(" ");
    //   return;
    // }

    // setWordCount(words.length);
    setSourceText(text);
    adjustHeight();
  };
  useEffect(()=>{
    const wordLength = (sourceText || "")
    .trim()
    .split(/\s+/)
    .filter((word) => word.length > 0).length;

  setWordCount(wordLength);
  },[sourceText])
  const adjustHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 380);
    textarea.style.height = `${newHeight}px`;
  };

  const getAnonymizeText = async () => {
    try {
      setError("")
      setLoader(true);
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "style_guide_generator",
        user_text: sourceText,
        source_language_code: inputLang,
      });
      const newShareId = response?.meta?.share_id;
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(`/tools/style-guide-generator/result?share-id=${newShareId}`);
    } catch (error) {
      setError(error);
    } finally {
      setLoader(false);
    }
  };

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);

      setSourceText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };

  return (
    <Layout>
      <SEO
        title="Translation Style Guide Generator"
        description="Create detailed style guides for your translation projects with our AI Style Guide Generator. Keep tone, terminology, and formatting consistent."
        slug="/tools/style-guide-generator"
      />
      <div>
        <div className="w-full py-7 ">
          <ToolsBreadcrumb />
        </div>

        <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] lg:pb-[60px] pt-12 pb-8 ">
          <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
            Style Guide Generator
          </h1>
          <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
            Generate an AI Style Guide to Save Time and Reduce Errors
          </h2>

          <div className="mt-12 flex lg:flex-row flex-col gap-4">
            <div className="relative shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans">
              <div className="flex justify-between">
                <span className="font-bold text-lg text-[#6E6E6E]">
                  {" "}
                  Source Text
                </span>
                {sourceText && (
                  <img
                    src={CleanButton}
                    className="cursor-pointer"
                    alt="clean-text-button"
                    onClick={() => {
                      setSourceText("");

                      setWordCount(0);
                    }}
                  />
                )}
              </div>

              <hr className="border border-[#B0B0B0] mt-2 mb-4" />

              {fileUploadLoading ? (
                <FileLoadingInput file={file} />
              ) : sizeLimitError && !fileUploadLoading ? (
                <ReUploadFile
                  file={file}
                  setFile={setFile}
                  setSizeLImitError={setSizeLImitError}
                  uploadFile={uploadFile}
                />
              ) : uploadError && !fileUploadLoading ? (
                <UnsupportedFile
                  file={file}
                  setSizeLImitError={setSizeLImitError}
                  setFile={setFile}
                  uploadFile={uploadFile}
                  setFileUploadError={setUploadError}
                />
              ) : (
                <div className="relative">
                  <textarea
                    dir={direction(inputLang)}
                    ref={textareaRef}
                    value={sourceText}
                    onChange={handleTextChange}
                    className={`anonymize_tool outline-none w-full resize-none min-h-[225px] max-h-[400px] text-base border-none `}
                    placeholder={textLoader ? "" : "Type your text here or"}
                  />
                  {textLoader && (
                    <div className="absolute top-0 left-[14px] text-[#B4B4B4] italic">
                      Adding sample text...
                    </div>
                  )}
                  {!sourceText && !textLoader && (
                    <button
                      className="absolute top-0 left-[170px] underline italic text-[#5B93FF] cursor-pointer pointer-events-auto"
                      onClick={() =>
                        trySampleText(setSourceText, setTextLoader)
                      }
                    >
                      Try Sample Text
                    </button>
                  )}
                </div>
              )}
              <div className={`mt-5 w-full bg-white  `}>
                <div className="flex md:flex-row items-end flex-col gap-2.5 justify-between">
                  <div className="flex-1">
                    {sourceText ? (
                      <p className="text-base text-[#9C9C9C] ">
                        {wordCount} words
                      </p>
                    ) : (
                      <>
                        <label
                          htmlFor="uploadFile"
                          className="flex gap-2 cursor-pointer"
                        >
                          <img src={UploadIcon} alt="upload_icon" />
                          <span className="text-[#858585] font-sm font-opensans font-semibold">
                            Upload File
                          </span>
                        </label>
                        <input
                          type="file"
                          className="hidden"
                          id="uploadFile"
                          onChange={uploadFile}
                        />
                      </>
                    )}
                  </div>
                  {error && !loader && (
                <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
                  {error}
                </span>
              )}
                  <button
                    disabled={!sourceText || !inputLang}
                    onClick={getAnonymizeText}
                    className={`${
                      !sourceText || !inputLang
                        ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed "
                        : "bg-lightBlue text-white"
                    }  outline-none font-opensans font-semibold text-base flex items-center justify-center px-6 py-3 rounded-lg gap-3 border `}
                  >
                    {loader ? <Loader /> : "Generate Style Guide"}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`relative shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans `}
            >
              <div className="flex justify-between items-center">
                <span className={`font-bold text-lg text-[#D2D2D2]`}>
                  Style Guide
                </span>
              </div>
              <hr className={`border mt-2 mb-4 border-[#D2D2D2]`} />

              <div
                className={`flex items-center justify-center pt-16 flex-col w-full overflow-x-hidden anonymize_tool min-h-[225px] max-h-[400px] border-none pr-2  `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="106"
                  height="97"
                  viewBox="0 0 106 97"
                  fill="none"
                >
                  <path
                    d="M106 11.7065V65.2253C106 71.6884 100.811 76.9318 94.4056 76.9318H42.1714L23.816 97V76.93H11.5944C5.19215 76.93 0 71.6866 0 65.2235V11.7065C0.00177002 5.24159 5.19215 0 11.5944 0H94.4056C100.811 0 106 5.24159 106 11.7065ZM32.1652 38.4641C32.1652 34.3093 28.8298 30.9394 24.7117 30.9394C20.5937 30.9394 17.2583 34.3093 17.2583 38.4641C17.2583 42.6225 20.5937 45.9906 24.7117 45.9906C28.8298 45.9906 32.1652 42.6243 32.1652 38.4641ZM60.4543 38.4641C60.4543 34.3093 57.1154 30.9394 53.0009 30.9394C48.8864 30.9394 45.5474 34.3093 45.5474 38.4641C45.5474 42.6225 48.8864 45.9906 53.0009 45.9906C57.1154 45.9906 60.4543 42.6243 60.4543 38.4641ZM88.7417 38.4641C88.7417 34.3093 85.4028 30.9394 81.2883 30.9394C77.1702 30.9394 73.8348 34.3093 73.8348 38.4641C73.8348 42.6225 77.1702 45.9906 81.2883 45.9906C85.4045 45.9924 88.7417 42.6243 88.7417 38.4641Z"
                    fill="#E8E8E8"
                  />
                </svg>
                <p className="text-center mt-8 text-[#858585]">
                  Your results will appear here.
                  <br /> Please enter your texts and click "Generate Style Guide" to
                  begin.
                </p>
              </div>
            </div>
          </div>
          <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans">
            Enter your text or project details and the tool generates a style
            guide to ensure consistency and accuracy in your translations. This
            helps streamline your workflow and deliver professional results.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={StyleGuideToolFaqData} />
    </Layout>
  );
};
export default Index;
