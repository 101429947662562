import React, { useContext, useState, useEffect } from "react";
import OrderSummary from "./OrderSummary";
import DataProvider from "Context/DataContext";
import SignInPopUp from "./SignInPopUp";
import FileUploadPopUp from "components/popup/quotePopUp/fileUploadPopUp";
import { useForm } from "react-hook-form";
import { UseDeleteFiles } from "hooks/Quotes/useDeleteFile";
import { usePostFileUpload } from "hooks/Quotes/usePostFileUpload";
import { usePostPlaceOrder } from "hooks/Quotes/usePostPlaceOrder";
import Loader from "components/loader";
import { navigate } from "gatsby";
import QuoteCard from "../firstComp/QuoteCard";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";

function ThirdStep({ setStep, setSelected }) {
  const { status, mutateAsync } = usePostPlaceOrder();
  const [openFilePopUp, setFilePopUp] = useState(false);
  const [emailFeild, setEmailFeild] = useState(true);
  const [emailValue, setEmailValue] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    thirdStepData,
    fileValue,
    quoteId,
    setfileValue,
    token,
    toggleOrder,
    settoggleOrder,
    setToken,
    accountInfo,
    setAccountInfo,
    LocalObject,
    userEmail,
    setUserMail,
    setUserName,
    userName,
    setStepsData,
    stepsData,
  } = useContext(DataProvider);

  useEffect(() => {
    const userData = LocalObject.localStorage.getItem("acc_info");
    if (userData) {
      setUserMail(JSON.parse(userData).user?.email);
      setEmailFeild(false);
      setAccountInfo(userData);
    }
  }, [token]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = useState(false);
  const [fileError, setFileError] = useState(false);

  const { status: fileStatus, mutateAsync: FileMutateAsync } =
    usePostFileUpload();

  // const onFileUpload = (e) => {
  //   const files = e.target.files;
  //   const formData = new FormData();
  //   formData.append("document", files[0]);
  //   formData.append("quote_id", quoteId);
  //   FileMutateAsync(formData).then((response) => {
  //     const FileResponse = response.data.quote_documents;
  //     setfileValue([...FileResponse]);
  //     setStepsData(response);
  //   });
  // };

  // const handleDeleteFile = (id) => {
  //   UseDeleteFiles(id).then((res) => {
  //     setStepsData(res);
  //     setfileValue(res.data.quote_documents);
  //   });
  // };
  const onFileUpload = (e) => {
    const files = Array.from(e.target.files);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("document[]", file);
    });
    formData.append("quote_id", quoteId);

    FileMutateAsync(formData).then((response) => {
      const FileResponse = response?.data?.quote_documents;
      setfileValue([...FileResponse]);
      setStepsData(response);
    });
  };

  const handleDeleteFile = (id) => {
    UseDeleteFiles(id, fileValue).then((res) => {
      if (res?.data?.quote_documents) {
        setfileValue(res.data?.quote_documents);
      }
    });
  };
  const handleReturn = () => {
    localStorage.setItem("step", 1);
    setStep((preStep) => (preStep = "1"));
    setSelected((preSelect) => (preSelect = "1"));
  };

  const logOut = () => {
    settoggleOrder(false);
    setToken(LocalObject.localStorage.removeItem("access_token"));
    setAccountInfo(LocalObject.localStorage.removeItem("acc_info"));
    setUserMail("");
    setEmailFeild(true);
    setEmailValue("");
  };
  const checkLogin = LocalObject.localStorage.getItem("access_token");
  checkLogin ? settoggleOrder(true) : settoggleOrder(false);

  const handleContinue = (data) => {
    setFileError(false);

    if (toggleOrder) {
      const userData = LocalObject.localStorage.getItem("acc_info");

      const postData = {
        quote_id: quoteId,
        customer_email: userEmail
          ? userEmail
          : LocalObject.localStorage.getItem("email"),
        project_name: data.project_name,
        special_instruction: data.special_instructions,
        customer_id: JSON.parse(userData)?.user?.id,
      };
      mutateAsync(postData).then((response) => {
        if (response.status === 1) {
          localStorage.setItem("order_id", response.data.quote.order_number);
          localStorage.removeItem("quote_id");
          localStorage.removeItem("project_name");
          localStorage.removeItem("email");
          localStorage.removeItem("step");
          return navigate("/quote/checkout");
        }
      });
    } else {
      // if(fileValue?.length < 1){
      //   setFileError(true)
      // }else{
      setStep((prevStep) => (prevStep = "4"));
      setSelected((selected) => (selected = "4"));
      localStorage.setItem("step", 4);
      localStorage.setItem("project_name", data.project_name);
      localStorage.setItem("email", data.email);
      localStorage.setItem("special_instructions", data.special_instructions);
      // }
    }
  };

  if (thirdStepData === {}) return null;
  return (
    <>
      <div className="flex items-center justify-center w-full">
        <form
          className="flex  justify-center flex-wrap lg:flex-nowrap flex-col xl:gap-10 w-full 2xl:w-[80%] xl:w-11/12  max-w-screen-2xl px-4 xl:px-0"
          onSubmit={handleSubmit(handleContinue)}
        >
          <p className="font-secondary font-semibold text-4xl py-5 text-center">
            Order Details
          </p>
          <div className="w-full flex items-center justify-center flex-wrap lg:flex-nowrap lg:gap-0 gap-y-5 flex-col-reverse md:flex-row">
            <div className="lg:w-[55%]  w-full">
              <SignInPopUp
                open={open}
                setOpen={setOpen}
                setEmailFeild={setEmailFeild}
                settoggleOrder={settoggleOrder}
                handleContinue={handleContinue}
              />
              <div className="flex items-center lg:items-start flex-col w-full">
                <div className="w-full flex items-center justify-between flex-wrap">
                  <p className="text-[11px] sm:text-17 xl:text-xl font-opensans font-bold mb-12">
                    {LocalObject.localStorage.getItem("access_token") ||
                    token ? (
                      <>
                        You're signed in as &nbsp;
                        <span className="cursor-pointer text-orange underline">
                          {userEmail || localStorage.getItem("email")
                            ? userEmail || localStorage.getItem("email")
                            : accountInfo?.user?.username
                            ? accountInfo?.user?.username
                            : ""}
                        </span>
                      </>
                    ) : (
                      <>
                        Have an account?{" "}
                        <span
                          onClick={() => setOpen(true)}
                          className="cursor-pointer text-orange underline"
                        >
                          Sign in
                        </span>
                        {"  "}
                        here.
                      </>
                    )}
                  </p>
                  {LocalObject.localStorage.getItem("access_token") || token ? (
                    <h3
                      className="text-[11px] sm:text-17 font-opensans font-semibold underline mb-12  cursor-pointer hover:text-orange md:mr-9"
                      onClick={logOut}
                    >
                      Sign Out
                    </h3>
                  ) : (
                    ""
                  )}
                </div>

                <div className="flex flex-wrap lg:gap-2 xl:gap-6 lg:flex-nowrap w-full 2xl:w-11/12 lg:w-11/12 justify-center lg:justify-between mb-5">
                  <label className="flex flex-col w-full font-opensans">
                    <span className="text-xl font-opensans"> Project Name</span>
                    <input
                      type="text"
                      value={stepsData?.data?.quote?.project_name}
                      name="project"
                      placeholder="e.g. my first project"
                      {...register("project_name", {
                        required: true,
                      })}
                      className="h-[50px] w w-full  min-w-[260px] lg:w-[260px] bg-white px-4 rounded-xl border border-[#CCCCCC] border-solid text-[#777777] mb-1"
                    />
                    {errors.name && (
                      <span className="text-xs font-opensans font-normal text-left w-full text-red">
                        {errorMessage}
                      </span>
                    )}
                  </label>
                  <label className="flex flex-col w-full font-opensans">
                    <span className="text-xl font-opensans">
                      {" "}
                      Email address*
                    </span>
                    {LocalObject.localStorage.getItem("access_token") ||
                    token ? (
                      <>
                        <input
                          type="email"
                          name="email"
                          disabled={true}
                          value={
                            userEmail
                              ? userEmail
                              : LocalObject.localStorage.getItem("email")
                              ? LocalObject.localStorage.getItem("email")
                              : ""
                          }
                          placeholder="e.g. example@example.com"
                          className="h-[50px]  w-full  min-w-[260px] lg:w-[260px] bg-white px-4 rounded-xl border border-[#CCCCCC] border-solid text-[#777777] mb-1"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="email"
                          name="email"
                          value={
                            LocalObject.localStorage.getItem("email")
                              ? LocalObject.localStorage.getItem("email")
                              : emailValue
                          }
                          placeholder="e.g. example@example.com"
                          {...register("email", {
                            required: true,
                            pattern: patternEmail,
                          })}
                          onChange={(e) => {
                            setEmailValue(e.target.value);
                          }}
                          className="h-[50px]  w-full  min-w-[260px] lg:w-[260px] bg-white px-4 rounded-xl border border-[#CCCCCC] border-solid text-[#777777] mb-1"
                        />
                        {errors.email && (
                          <span className="text-xs font-opensans font-normal text-left w-full text-red">
                            {errorMessageEmail}
                          </span>
                        )}
                      </>
                    )}
                  </label>
                </div>
                <FileUploadPopUp
                  setFilePopUp={setFilePopUp}
                  openFilePopUp={openFilePopUp}
                  onFileUpload={onFileUpload}
                  fileValue={fileValue}
                  fileStatus={fileStatus}
                  handleDeleteFile={handleDeleteFile}
                />
                <div className="flex flex-wrap lg:flex-nowrap w-full 2xl:w-11/12 lg:w-11/12 justify-center lg:justify-between mb-5">
                  <label className="flex flex-col w-full">
                    <span className="text-xl font-opensans">Notes</span>
                    <textarea
                      name="special_instructions"
                      placeholder="Additional information..."
                      className=" p-4 w-full bg-white rounded-xl border border-[#CCCCCC] border-solid text-[#777777] mb-5"
                      rows="2"
                      {...register("special_instructions")}
                    >
                      {LocalObject.localStorage.getItem("special_instructions")
                        ? LocalObject.localStorage.getItem(
                            "special_instructions"
                          )
                        : ""}
                    </textarea>
                  </label>
                </div>
                {fileValue?.length > 0 ? (
                  <div className="flex w-full 2xl:w-11/12 lg:w-11/12 justify-center flex-col ">
                    {fileValue?.map(({ originalName, id, noOfWords }) => (
                      <div
                        className="flex justify-between px-4 items-center w-full border mb-2 border-borderGray py-2 rounded-xl bg-[#F8F9F9]"
                        key={id}
                      >
                        <p className="text-lg font-arial">{originalName} </p>
                        <p className="text-lg font-arial">{`(${noOfWords} words)`}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-wrap lg:flex-nowrap w-full 2xl:w-11/12 lg:w-11/12 justify-center lg:justify-between ">
                    <label className="flex flex-col w-full">
                      <span className="text-xl font-opensans font-medium text-left">
                        Upload your files
                      </span>
                      <div
                        className="w-full flex justify-start items-center font-arial h-[50px]  bg-white px-4 rounded-xl border border-[#CCCCCC] border-solid text-[#777777] mb-5"
                        onClick={() => setFilePopUp(true)}
                      >
                        <>
                          <p
                            className="bg-quotetext-400 whitespace-nowrap text-xs font-medium text-white font-secondary px-1 rounded py-1 mr-2 cursor-pointer"
                            onClick={() => setFilePopUp(true)}
                          >
                            Choose File
                          </p>
                          <p className="cursor-pointer w-8/12">
                            {" "}
                            {fileValue?.length !== 0
                              ? `| ${fileValue?.length} ${
                                  fileValue?.length === 1 ? "File" : "Files"
                                }`
                              : "No file chosen"}
                          </p>
                        </>
                      </div>
                      {errors.radio && (
                        <span className="text-red block ">{errorMessage}</span>
                      )}{" "}
                    </label>
                    <label className="flex ml-3 flex-wrap w-full">
                      <div className="w-full flex items-center">
                        <input
                          type="radio"
                          name="radio"
                          placeholder="Additional information..."
                          className=" p-4 mr-2"
                          {...register("radio", { required: true })}
                        />
                        <p className="font-opensans font-bold text-lg">
                          I don't have files yet
                        </p>
                      </div>
                    </label>
                  </div>
                )}
              </div>
            </div>
            <OrderSummary setStep={setStep} setSelected={setSelected} />
          </div>
          <div className="w-full  h-[1px] bg-quotetext-100 xl:-mt-5 mt-8 hidden sm:block"></div>
          <div className="flex flex-col-reverse gap-5 sm:gap-0 sm:flex-row items-center  justify-between w-full xl:mt-0 mt-6 mb-3 lg:mb-0">
            <div className="flex flex-col sm:flex-row justify-center sm:justify-between">
              <p
                className="text-lg text-quotetext-200 hover:text-orange underline cursor-pointer"
                onClick={handleReturn}
              >
                {"<Return to Purchase Options"}
              </p>
            </div>
            <button
              className="text-xl font-medium text-white font-secondary sm:max-w-[211px] w-full h-[53px] rounded-full bg-orange "
              type="submit"
            >
              {toggleOrder ? (
                status === "loading" ? (
                  <Loader />
                ) : (
                  "Place Order"
                )
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </form>
      </div>
      <div className="w-full 2xl:w-[65%] xl:w-10/12  max-w-screen-2xl px-4 xl:px-0 sm:mt-5">
        <QuoteCard />
      </div>
    </>
  );
}

export default ThirdStep;


