import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLanguageIdentifier } from "hooks/Forms/useLanguageIdentifier";
import Loader from "components/loader";
import formCommonPostData from "utils/form-common-post-data";
import { Link, navigate } from "gatsby";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import UploadIcon from "assets/uploadIcon.svg";
import { fileExtension } from "utils/fileextension";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import CleanButton from "assets/cleanBtn.svg";
import useDebounce from "components/QualityTools/useDebounce";
import detectLanguage from "utils/detectLanguage";
import { direction } from "utils/direction";
import { trySampleText } from "utils/sampletext-generator";

const LanguageIdentifier = () => {
  const { status, mutateAsync } = useLanguageIdentifier();
  const [inputLang, setInputLang] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inputText, setInputText] = useState("");
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [file, setFile] = useState(false);
  const [textLoader, setTextLoader] = useState(false);

  const debouncedInputText = useDebounce(inputText, 3000);
  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setError("")
    if (inputText.trim() === "") {
      alert("Please enter text in the textarea box");
      return false;
    }

    setLoading(true);

    const postData = {
      tool_name: "language_detector",
      user_text: inputText,
      source_language_code: inputLang,
      // text: data.message,
      ...formCommonPostData(),
    };

    try {
      const response = await mutateAsync(postData);
      setLoading(false);
      const newShareId = response?.meta?.share_id;
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(`/tools/language-detector/result?share-id=${newShareId}`);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    setInputText(text);
  };
  useEffect(() => {
    const words = inputText.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(words.length);
  }, [inputText]);

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);

      setInputText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };

  const clearText = () => {
    setWordCount(0);
    setInputText("");
    reset({ message: "" });
    setInputLang("");
    setError("");
  };

  return (
    <div className="bg-[#F5F5F5] ">
      <div className="w-full  py-7 ">
        <ToolsBreadcrumb />
      </div>

      <div className="py-12 lg:py-24 max-w-7xl mx-auto px-4 grid lg:grid-cols-2 gap-12">
        <div>
          <h1 className="text-[#5B93FF] mt-0 lg:mt-4 text-center md:text-start text-base font-primary font-semibold leading-6 mb-2.5">
            DETECT LANGUAGE
          </h1>
          <h2 className="text-[#0A2641] text-center md:text-start font-bold text-[28px] lg:text-[48px] leading-[48px] lg:leading-[56px] font-primary">
            Instant language detection of your text
          </h2>
          <p className="hidden md:block text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] pt-4">
            Detect the language of your text instantly, whether it’s in English,
            Spanish, or other languages. Enter your text, and click the button
            to find out what language your text is in. This language detector
            can identify multiple languages present in a given text.
          </p>
        </div>
        <div>
          <form
            action=""
            className="relative shadow-lg rounded-lg bg-white"
            onSubmit={handleSubmit(onSubmit)}
          >
            {fileUploadLoading ? (
              <FileLoadingInput file={file} />
            ) : sizeLimitError && !fileUploadLoading ? (
              <ReUploadFile
                file={file}
                setFile={setFile}
                setSizeLImitError={setSizeLImitError}
                uploadFile={uploadFile}
              />
            ) : uploadError && !fileUploadLoading ? (
              <UnsupportedFile
                file={file}
                setSizeLImitError={setSizeLImitError}
                setFile={setFile}
                uploadFile={uploadFile}
                setFileUploadError={setUploadError}
              />
            ) : (
              <div className="relative">
                <textarea
                  dir={direction(inputLang)}
                  placeholder={textLoader ? "" : "Type your text here or"}
                  name="message"
                  id="message"
                  {...register("message")}
                  className={`anonymize_tool  ${
                    direction(inputLang) === "rtl" ? "pl-8 pr-5" : "pl-5 pr-8"
                  } py-5  min-h-[240px] placeholder-[#B4B4B4] text-black font-primary bg-transparent text-[15px] overscroll-y-none resize-none border-none outline-none block w-full`}
                  onChange={handleTextChange}
                  value={inputText}
                  dangerouslySetInnerHTML={{
                    __html: inputText.replace(/\n/g, "<br />"),
                  }}
                />
                {textLoader && (
                  <div className="absolute top-[17px] left-[14px] text-[#B4B4B4] italic">
                    Adding sample text...
                  </div>
                )}
                {!inputText && !textLoader && (
                  <button
                    className="absolute top-[17px] left-[182px] underline italic text-[#5B93FF] cursor-pointer pointer-events-auto"
                    onClick={() => trySampleText(setInputText, setTextLoader)}
                  >
                    Try Sample Text
                  </button>
                )}
              </div>
            )}

            {wordCount > 0 && (
              <p
                className={`absolute top-[14px]  ${
                  direction(inputLang) === "rtl"
                    ? "left-[10px]"
                    : "right-[16px]"
                }  w-max cursor-pointer`}
                onClick={clearText}
              >
                <img src={CleanButton} alt="clean-button" />
              </p>
            )}

            <div className="flex justify-between items-center p-4">
              {inputText ? (
                <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                  word count: {wordCount}
                </p>
              ) : (
                <>
                  <label
                    htmlFor="uploadFile"
                    className="flex gap-2 cursor-pointer"
                  >
                    <img src={UploadIcon} alt="upload_icon" />
                    <span>Upload File</span>
                  </label>
                  <input
                    type="file"
                    className="hidden"
                    id="uploadFile"
                    onChange={uploadFile}
                  />
                </>
              )}
              <button
                disabled={!inputText || !inputLang}
                type="submit"
                className={`${
                  !inputText || !inputLang
                    ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed "
                    : "bg-lightBlue text-white"
                }   text-base font-semibold  font-opensans text-center rounded-md block px-6 py-2`}
              >
                {/* {loading ? <Loader /> : "Detect language"} */}
                Identify language
              </button>
            </div>
          </form>
          <div className="mt-4 rounded-lg bg-white w-full px-4 py-6 shadow-lg">
            <div className="w-full md:flex justify-between items-center">
              <p className="text-[#858585] font-opensans text-semibold leading-6">
                Language detected:
              </p>
              {error && status !== "loading" && (
                <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
                  {error}
                </span>
              )}
              <div className="text-[#858585] mt-4 md:mt-0 font-opensans text-semibold leading-6">
                {status === "loading" ? (
                  <p className="flex">
                    Loading...
                    <Loader />
                  </p>
                ) : (
                  "--"
                )}
              </div>
            </div>
          </div>
          <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
        </div>
        <p className="block md:hidden text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] pt-4">
          Detect the language of your text instantly, whether it’s in English,
          Spanish, or other languages. Enter your text, and click the button to
          find out what language your text is in. This language detector can
          identify multiple languages present in a given text.
        </p>
      </div>
    </div>
  );
};

export default LanguageIdentifier;
