import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import CleanButton from "assets/cleanBtn.svg";

const SourceBox = ({
  sourceText,
  setSourceText,
  outputText,
  setOutputText,
  textareaRef,
  outputRef,
  inputLang
}) => {
  const [wordCount, setWordCount] = useState(0);

  const handleTextChange = (e) => {
    const text = e.target.value;


    // if (words.length > 1000) {
    //   e.target.value = words.slice(0, 1000).join(" ");
    //   return;
    // }

    
    setSourceText(text);
    setOutputText("");
    adjustHeight();
  };
  useEffect(()=>{
    
    const words = sourceText
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);
    setWordCount(words.length);
  },[sourceText])

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 380);
    textarea.style.height = `${newHeight}px`;
  };

  return (
    <div className="shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans">
      <div className="flex justify-between">
        <span className="font-bold text-lg text-[#6E6E6E]"> Source Text</span>
        {sourceText && (
          <img
            src={CleanButton}
            className="cursor-pointer"
            alt="clean-text-button"
            onClick={() => {
              setSourceText("");
              setOutputText("");
              setWordCount(0);

              navigate(`/tools/data-anonymization/`);
            }}
          />
        )}
      </div>

      <hr className="border border-[#B0B0B0] mt-2 mb-4" />

      <textarea
      dir={inputLang === "he" || inputLang === "ar" ? "rtl" : "ltr"}
        ref={textareaRef}
        value={sourceText}
        dangerouslySetInnerHTML={{ __html: sourceText.replace(/\n/g, "<br />") }}
        onChange={handleTextChange}
        className={`anonymize_tool  outline-none w-full resize-none text-base border-none  min-h-[325px] `}
        // className={`anonymize_tool  outline-none w-full resize-none text-base border-none ${
        //   outputRef?.current?.offsetHeight
        //     ? `max-h-[${outputRef?.current?.offsetHeight }px]`
        //     : "min-h-[325px]"
        // }  `}
        placeholder="Enter your text here"
        
      />

      <div className="mt-5">
        <div className="flex md:flex-row items-end flex-col gap-2.5 justify-between">
          <div className="flex-1">
            <p className="text-base text-[#9C9C9C] ">{wordCount} words</p>
          </div>
          <button
            disabled={outputText || !sourceText}
            className={` outline-none font-opensans bg-[#E8E8E8] border-[#E8E8E8] font-semibold text-base flex items-center justify-center px-6 py-3 rounded-lg gap-3 border `}
          >
            Anonymize my data
          </button>
        </div>

        <p className="text-[12px] text-[#6E6E6E] bg-[#F0F5FF] p-2 rounded-lg mt-3 font-opensans">
          This AI tool may not catch all sensitive information. Check the output
          before sharing.
        </p>
      </div>
    </div>
  );
};

export default SourceBox;
