import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBanner from "components/commontools/toolsBanner";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useState, useRef, useEffect } from "react";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import ToolFaq from "components/QualityTools/ToolFaq";
import { aiWritingAssistant } from "components/FAQ/config";
import { navigate } from "gatsby";
import useDebounce from "components/QualityTools/useDebounce";
import detectLanguage from "utils/detectLanguage";
import Guidelines from "components/AIWritingAssistant/Guidelines";
import ClearText from "components/ClearText";
import Loader from "components/AIWritingAssistant/loader";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [guidelines, setGuideLines] = useState("");
  const [inputText, setInputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const refDiv = useRef(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const debouncedInputText = useDebounce(inputText, 3000);
  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
    }
  };

  const handleClick = async () => {
    setLoading(true);
    const payload = {
      tool_name: "ai_editing_assistant",
      user_text: inputText,
      source_language_code: inputLang ?? "en",
    };
    if (guidelines) {
      payload.guideline = guidelines;
    }
    try {
      const response = await http().post(endpoints.forms.aiTools, payload);
      const newShareId = response?.meta?.share_id;
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(`/tools/ai-writing-assistant/result?share-id=${newShareId}`);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const handleClearText = () => {
    setInputLang("");
    setInputText("");
    setWordCountInput(0);
  };

  return (
    <Layout>
      <SEO
        title="Free AI Text Editor"
        description="Edit text effortlessly with the AI-Powered Text Editing Tool. Input custom instructions to refine text in multiple languages with precision and speed. Fast, flexible, and user-friendly."
        slug="/tools/ai-writing-assistant​​​"
      />
      <div className="bg-[#F5F5F5]">
        <div className="pt-5">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="AI Text Editing Tool"
            title="Edit Multilingual Texts Quickly and Easily Using AI"
            description="This AI Text Editor makes text editing simpler than ever. Write custom instructions to tailor your multilingual text to your needs. It’s the perfect tool for refining your writing with accuracy and speed."
          />
          <div className=" flex lg:flex-row  flex-col gap-4 mt-12">
            <div className="flex flex-col gap-3 w-full lg:flex-[1.2]">
              <div className="lg:flex items-center  rounded-lg shadow-lg bg-white ">
                {loading ? (
                  <div className="h-[345px] w-full relative p-4">
                    <ClearText
                      text="loading"
                      handleClearText={handleClearText}
                    />
                    <div className="h-full grid place-content-center">
                      <Loader />
                    </div>
                  </div>
                ) : (
                  <ToolInput
                    lang={inputLang}
                    text={inputText}
                    wordCount={wordCountInput}
                    setText={setInputText}
                    setInputLang={setInputLang}
                    setWordCount={setWordCountInput}
                    iscleanBtn={true}
                    isDifficulty={true}
                    refDiv={refDiv}
                    handleClearText={handleClearText}
                    placeholder="Type your text here or"
                    tooltype="pre"
                  >
                    <ClearText
                      text={inputText}
                      handleClearText={handleClearText}
                    />
                  </ToolInput>
                )}
              </div>
            </div>
            <Guidelines
              guidelines={guidelines}
              setGuidelines={setGuideLines}
              inputText={inputText}
              handleClick={handleClick}
              loading={loading}
            />
          </div>

          <p className="mt-8 md:mt-12 text-lg text-center font-opensans text-[#424242]">
            This AI Text Editor makes text editing simpler than ever. Write
            custom instructions to tailor your multilingual text to your needs.
            It’s the perfect tool for refining your writing with accuracy and
            speed.
          </p>
        </div>
      </div>
      <div>
        <ToolFaq QaToolFaqData={aiWritingAssistant} />
      </div>
    </Layout>
  );
};

export default Index;
