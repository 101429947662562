


import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";



import React, { useEffect } from "react";

const Index = () => {

  useEffect(() => {
    const handleIframeMessage = (event) => {

      // const id = event.data.message.share_id
      // const outputlang = event.data.message.targetlang
      // if(id){
      //   navigate(`/tools/mt-translation/result?share-id=${id}`)
      //   // const url = `http://localhost:3001/translations/${id}`;
      //   // window.open(url, "_blank");
      // }

      const allowedOrigins = ["https://iframe1.com", "https://iframe2.com"];
      if (!allowedOrigins.includes(event.origin)) return;

      const { type, data } = event.data;
      if (type === "iframe-success") {
        const id = data.message;

        // if (id) {
        //   navigate(`/tools/mt-translation/result?share-id=${id}`)
        //   // const url = `https://machine-translation-next-git-dev-bytequest.vercel.app/translations/${id}`;

        //   // window.open(url, "_blank");
        // }
      }
      if (type === "iframe-error") {
        console.error(data.message || "An error occurred in the iframe");
      }
    };

    window.addEventListener("message", handleIframeMessage);

    return () => window.removeEventListener("message", handleIframeMessage);
  }, []);

  return (
    <Layout>
           <SEO
        title="Free AI Translator Tool"
        description="Experience the power of translation aggregation with the Free AI Translator Tool. Compare results from leading AI and LLM sources, access quality scores, and get insights to ensure the best translations."
        slug="/tools/ai-translator"
      />
      <div className="bg-[#F5F5F5] pb-24">
        <div className="pt-7">
          <ToolsBreadcrumb widget={true} />
        </div>
        <iframe
          width="100%"
          height="600"
          src={`https://www.machinetranslation.com/widget`}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className=""
          loading="lazy"
        ></iframe>
       <p className="text-center max-w-7xl mx-auto px-4 mt-12">The Free AI Translator Tool, powered by MachineTranslation.com, revolutionizes translation by aggregating outputs from the best AI and LLM sources. With detailed quality scores and actionable insights, this tool ensures you choose the most accurate and contextually appropriate translation for your needs. Perfect for multilingual communication with unparalleled speed and efficiency.</p>
      </div>
    </Layout>
  );
};

export default Index;
