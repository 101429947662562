
import React from "react";
import OutputHeader from "./OutputHeader";
import * as XLSX from "xlsx";

const StyleGuideOutput  = ({ header, styleOutput,tooltiptext,languagecode }) => {
  const handleCopy = () => {
    const text = document.getElementById("style-output").innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy data: ", error);
      });
  };

  const styleGuideEntries = styleOutput
  ? Object.entries(styleOutput).map(([term, definition]) => ({
      Term: term,
      Definition: definition,
    }))
  : [];
  const downloadata = () => {
    const data = styleGuideEntries?.map((item) => ({
      Terms: item.Term,
      Defination: item.Definition,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Special Terms");

   
    XLSX.writeFile(wb, "style_guide.xlsx");
  };

  return (
    <div className="mt-5 shadow-box p-5 h-max rounded-lg border border-lightBlue">
      <OutputHeader title={header} tooltiptext={tooltiptext} downloadData={downloadata} copyText={handleCopy} />
      <div className="flex flex-col gap-2.5" id="style-output">
      
      {styleGuideEntries.map((item, index) => (
          <li className="flex" key={index}>
            <p  className="p-3 w-full lg:max-w-[200px] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
              {item.Term}
            </p>
            <p className="p-3 bg-[#F0F5FF] w-full border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
              {item.Definition}
            </p>
          </li>
        ))}
     
      </div>
    </div>

  );
};

export default StyleGuideOutput ;

  